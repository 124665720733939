<template lang="pug">
div(v-if='$root.$data.state.common.isLogin')
  select-directory(:postID='post.id')
  .w-4
  share-post(:postID='post.id')
  .w-4
  revise-upload-file(readUserID
    :postID="post.id"
    :fileID="post.fileID"
    @readDirectory="readDirectory"
    v-if="$root.$data.state.common.userID===readUserID && $root.$data.state.common.isLogin && currentPath.startsWith('home/upload')")
  revise-track-file(
    :postID="post.id"
    :fileID="post.fileID"
    v-if="$root.$data.state.common.userID===readUserID && $root.$data.state.common.isLogin && currentPath.startsWith('home/track')"
    @readDirectoryAndClear="readDirectoryAndClear")
</template>
<script>
import SelectDirectory from '../Common/SelectDirectory.vue'
import SharePost from '../Common/SharePost.vue'
import ReviseUploadFile from '../../Directory/Profile/ReviseUploadFile.vue'
import ReviseTrackFile from '../../Directory/Profile/ReviseTrackFile.vue'

export default {
  components: { SelectDirectory, SharePost, ReviseUploadFile, ReviseTrackFile },
  name: 'MasonryImageSelect',
  props: ['post', 'readUserID', 'currentPath'],
  data () {
    return {
    }
  },
  mounted () {
  },
  methods: {
    readDirectory () {
      this.$emit('readDirectory')
    },
    readDirectoryAndClear () {
      this.$emit('readDirectoryAndClear')
    }
  }
}
</script>
