import axios from 'axios'
import serverIP from './common'

const requestHelper = axios.create({
  baseURL: serverIP.backEndServer
})

requestHelper.interceptors.request.use(function (config) {
  const token = `Bearer ${localStorage.getItem('user-token')}`
  config.headers.Authorization = token
  return config
})

requestHelper.interceptors.response.use(response => {
  return response
}, error => {
  if (error.response.status && error.response.status === 401) {
    location.href = `${serverIP.frontEndServer}/login`
    alert('請重新登入')
  }
  return error
})

export default requestHelper
