<template>
  <div>
    <v-row>
      <v-col cols="3">
        <v-list
          two-line
          style="overflow: auto"
          height="700"
          color="rgba(0,0,0,0)"
        >
          <v-list-item-group v-model="selected" active-class="blue lighten-4">
            <template v-for="(user, index) in userList">
              <v-list-item
                :key="index + '-user'"
                :value="user[0]"
                @click="searchUserOrder(user[0])"
              >
                <v-list-item-icon>
                  <v-icon>mdi-account</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{user[1]}}<br>{{user[2]}}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-divider
                v-if="index < userList.length - 1"
                :key="index"
              ></v-divider>
            </template>
          </v-list-item-group>
        </v-list>
      </v-col>
      <v-col cols="9">
        <v-card flat>
          <v-card-text>
            <v-card-title>付款資訊</v-card-title>
            <v-row>
              <v-col>
                <v-data-table
                  :headers="paymentsTableHeaders"
                  :items="payments"
                  class="elevation-1"
                >
                  <template v-slot:item.actions="{ item }">
                    <v-icon
                      v-if="item.state === 'success'"
                      @click="cancelItem(item)"
                    >
                      mdi-close-thick
                    </v-icon>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import userAPI from '../../../apis/user'
import payAPI from '../../../apis/pay'

export default {
  name: 'UserList',
  data () {
    return {
      selected: null,
      paymentsTableHeaders: [
        { text: 'ID', value: 'id' },
        { text: '交易日期', value: 'tradeDate' },
        { text: '狀態', value: 'state' },
        { text: '方案名稱', value: 'item' },
        { text: '價格', value: 'price' },
        { text: '期間', value: 'during' },
        { text: '開始時間', value: 'startDate' },
        { text: '結束時間', value: 'endDate' },
        { text: '動作', value: 'actions', sortable: false }
      ],
      userList: [],
      payments: []
    }
  },
  mounted () {
    this.getALLUsernameAndRole()
  },
  methods: {
    getALLUsernameAndRole () {
      userAPI.getALLUsernameAndRole().then((response) => {
        this.userList = response.data
      })
    },
    searchUserOrder (userID) {
      payAPI.searchUserOrder(userID).then((response) => {
        this.payments = response.data
      })
    },
    cancelItem (item) {
      if (confirm('確認取消?') === true) {
        payAPI.cancelOrder(item.id).then((response) => {
          this.searchUserOrder(this.selected)
        })
      }
    }
  }
}
</script>
