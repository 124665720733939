<template lang="pug">
v-menu(v-model='showMenu', offset-y='', :close-on-content-click='false')
  template(v-slot:activator='{ on, attrs }')
    .bg-white.rounded.flex.justify-center.items-center.w-9.h-9(v-bind='attrs', v-on='on', :class="border ? 'border':''")
      v-tooltip(top)
        template(v-slot:activator="{ on, attrs }")
          img.w-5.h-5(src='@/assets/home/folder_icon.png',v-bind="attrs", v-on="on")
        span 收藏
  v-list(style='overflow-y:auto; height:460px')
    .absolute.right-2.top-2.z-50
      v-icon(@click="showMenu = false") mdi-close
    v-list-item
      .text-lg.font-medium 選擇收藏目錄
    v-list-item(v-if="currentPath.path != 'home/track'")
      .flex.items-center.cursor-pointer(@click='previousPage')
        img.w-2.mr-2(src="@/assets/icons/last_page_icon.png")
        .mr-1 回到上一層
    v-list-item-content
      v-list-item-title
        .flex.justify-between.px-4
          .text-base {{ altedCurrentPath }}
          .w-32(class="md:w-40")
          .btn.bg-gray.mr-1.hover-pri(@click='addFile(currentPath.path)') 儲存
    v-list-item(v-for='(directory, index) in directorys', :key='index')
      .cursor-pointer.flex.justify-between.items-center.border.w-full.px-2.py-2(:class="{'rounded-t': index==0,'rounded-b': index==directorys.length-1}" @click='nextDirectory(directory.name)')
          .text-base {{ directory.name }}
          .w-32(class="md:w-40")
          .flex
            img.w-2.mr-2(style="object-fit:contain" src="@/assets/icons/next_page_icon.png" )
            .btn.bg-gray.mr-1.hover-pri.max-h-min(@click.stop="addFile(currentPath.path+'/'+directory.name)") 儲存
    create-directory(@refreshDirectory='readDirectory', :currentPath='currentPath.path')
</template>
<script>
import createDirectory from './CreateDirectory.vue'
import directoryAPI from '../../../apis/directory'

export default {
  components: { createDirectory },
  name: 'SelectDirectory',
  props: {
    postID: {},

    border: {
      default: false
    }
  },
  data () {
    return {
      showMenu: false,
      directorys: [],
      currentPath: {
        path: 'home/track',
        userID: '',
        readUserID: '',
        page: 0,
        size: 1
      },
      directoryInputDS: {
        path: '',
        postID: ''
      }
    }
  },
  mounted () {
    this.currentPath.userID = this.$root.$data.state.common.userID
    this.currentPath.readUserID = this.$root.$data.state.common.userID
  },
  computed: {
    altedCurrentPath () {
      if (this.currentPath.path.startsWith('home/track')) {
        return `最愛收藏${this.currentPath.path.split('home/track')[1]}`
      } else if (this.currentPath.path.startsWith('home/upload')) {
        return `收藏品${this.currentPath.path.split('home/upload')[1]}`
      }
      return this.currentPath.path
    }
  },
  watch: {
    showMenu: function (newDialog, oldDialog) {
      if (newDialog) { this.readDirectory() }
    }
  },
  methods: {
    nextDirectory (dirName) {
      this.currentPath.path = this.currentPath.path + '/' + dirName
      this.readDirectory()
    },
    previousPage () {
      let newPath = ''
      const temp = this.currentPath.path.split('/')
      for (let i = 0; i < temp.length - 1; i++) {
        newPath += temp[i] + '/'
      }
      this.currentPath.path = newPath.substr(0, newPath.length - 1)
      this.readDirectory()
    },

    setSnackbarCustomIsOpenAction () {
      this.$root.$data.setSnackbarCustomIsOpenAction(true)
    },
    setSnackbarCustomMessageAction (message) {
      this.$root.$data.setSnackbarCustomMessageAction(message)
    },
    readDirectory () {
      directoryAPI.readDirectory(this.currentPath).then((response) => {
        const data = response.data
        this.directorys = data.filter((directory) => directory.type === 'dir')
      })
    },
    addFile (dirName) {
      this.directoryInputDS.path = dirName
      this.directoryInputDS.postID = this.postID
      directoryAPI.addFile(this.directoryInputDS).then((response) => {
        let message = ''
        if (response.data === true) {
          this.readDirectory()
          this.showMenu = false
          message = '已儲存'
        } else {
          message = '儲存失敗'
        }
        this.setSnackbarCustomIsOpenAction()
        this.setSnackbarCustomMessageAction(message)
      })
    }
  }
}
</script>
