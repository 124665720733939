var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',[_c('v-card',{attrs:{"flat":""}},[_c('v-card-text',[_c('v-card-title',[_vm._v("檢舉")]),_c('v-row',[_c('v-col',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.reportTableHeaders,"items":_vm.reports,"sort-by":['status']},scopedSlots:_vm._u([{key:"item.postID",fn:function(ref){
var item = ref.item;
return [_c('a',{attrs:{"target":"_blank","href":(_vm.frontEndServer + "/post/" + (item.postID))}},[_vm._v(_vm._s(item.postID))])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.status ? "已處理" : "未處理")+" ")]}},{key:"item.auditResults",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.auditResults ? "無異常" : "異常")+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(!item.status)?_c('div',[_c('v-icon',{staticClass:"mr-2",on:{"click":function($event){return _vm.reviseAuditResultsForPostReport(
                          item.id,
                          item.postID,
                          true
                        )}}},[_vm._v(" mdi-circle-outline ")]),_c('v-icon',{on:{"click":function($event){return _vm.reviseAuditResultsForPostReport(
                          item.id,
                          item.postID,
                          false
                        )}}},[_vm._v(" mdi-close-thick ")])],1):_vm._e()]}}])})],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }