<template lang="pug">
.flex.justify-center.items-center.h-full.w-screen.bg-create-post
  v-card.pa-6.box-border.max-w-full(class="md:w-7/12" )
    .flex.flex-col(class="md:flex-row")
      .flex.flex-col.pr-4.box-border(style="flex:4 !important")
        .rounded.w-full.flex.justify-center.items-center(style='width: 100%; aspect-ratio:1; background: #000' v-if="images.length != 0" )
          img.w-full.h-full(:src="currentImage", style="object-fit:contain")
        .rounded.overflow-hidden(style='width: 100%; aspect-ratio: 1;' v-else)
          .upload-img.border-dashed.border-2.w-full.h-full.flex.items-end
            .text-sm.text-gray-400.p-2
              | 照片格式: .jpg <br>
              | 單一檔案大小限制: 20 MB｜可多張上傳 <br>
        .h-3
        .img-wrapper
          .m-1.overflow-hidden.relative.transition-all(class="imageItem" :key='index' @drop='drop' @dragover='allowDrop' v-for='(image, index) in images' style="width:100%; aspect-ratio: 1;")
            img.w-full.h-full(style="object-fit:cover" :id='index' draggable='true' @dragstart='drag' width='100%' :src='image.url' @click='imageClick(index)')
            .absolute.icon.top-0.cursor-pointer(@click="deletePicture(index)")
              v-icon.mr-2.bg-pri(color="white" ) mdi-close
          .m-1.flex.justify-center.items-center(:key='index + "empty"' v-for='(image, index) in 10 - (images.length)' style="width:100%; aspect-ratio: 1;background-color: #ECECEC;")
            v-file-input.upload-icon.ml-2.p-0.justify-center.items-center(v-model='uploadFile' prepend-icon="mdi-plus" truncate-length="1" hide-input multiple @change='previewImage' label='圖片')
        .text-xs.text-gray-500.mt-2
          | 電腦網頁版，可拖曳圖片來排序
      .w-5
      .flex.flex-col.flex-1(style="flex:5 !important")
        .flex.items-center
          select-directory-for-create-post(@selectDirectory='selectDirectory')
          .w-3
          h2 {{ altedCurrentPath(post.path) }}
        input.text-2xl.mt-6(type='text' v-model='post.title' placeholder="請輸入藏品名稱" style="border: none;margin-top: 10px")
        v-divider.mt-2
        .flex.flex-col
          v-textarea.mt-6(v-model='post.content' outlined label='內容(朝代/來源/尺寸等)')
          v-text-field.mt-6(v-model='post.url' outlined label='連結網址(若無不需輸入)')
          .flex.justify-end
            .btn.mr-4(v-if='loading === false' @click='$router.go(-1)') 取消
            .btn.bg-pri(v-if='loading === false' @click='createPost') 上傳
  .fixed.w-screen.h-screen.z-50.top-0.flex.justify-center.items-center.flex-col.bg-black.bg-opacity-60(v-if='loading === true')
    .loader
    .text-base.text-white.mt-2 載入中..

</template>
<script>
import SelectDirectoryForCreatePost from './CreatePost/SelectDirectoryForCreatePost.vue'
import draggable from 'vuedraggable'

import postAPI from '../../apis/post'

export default {
  components: { SelectDirectoryForCreatePost, draggable },
  name: 'CreatePost',
  data () {
    return {
      currentImage: '',
      images: [],
      post: {
        title: null,
        content: null,
        pictureList: null,
        url: 'https://',
        path: null
      },
      uploadFile: null,
      loading: false
    }
  },
  methods: {
    altedCurrentPath (path) {
      if (!path) {
        return path
      }
      if (path.startsWith('home/track')) {
        return `最愛收藏${path.split('home/track')[1]}`
      } else if (path.startsWith('home/upload')) {
        return `收藏品${path.split('home/upload')[1]}`
      }
      return path
    },
    previewImage () {
      for (let i = 0; i < this.uploadFile.length; i++) {
        const reader = new FileReader()
        reader.onload = (e) => {
          for (var i = 0; i < this.images.length; i++) {
            if (this.images[i].url === e.target.result) {
              return
            }
          }
          this.images.push({
            url: e.target.result,
            id: i,
            uploadFile: this.uploadFile[i]
          })
          if (i === 0) {
            this.currentImage = this.images[0].url
          }
        }
        reader.readAsDataURL(this.uploadFile[i])
      }
    },
    imageClick (id) {
      this.currentImage = this.images[id].url
    },
    allowDrop (ev) {
      ev.preventDefault()
    },
    drag (ev) {
      ev.dataTransfer.setData('id', ev.target.id)
    },
    drop (ev) {
      ev.preventDefault()
      const data = ev.dataTransfer.getData('id')
      const temp1 = this.images[data]
      const temp2 = this.images[ev.target.id]
      this.images.splice(ev.target.id, 1, temp1)
      this.images.splice(data, 1, temp2)
      this.currentImage = this.images[ev.target.id].url
    },
    selectDirectory (path) {
      this.post.path = path
    },
    deletePicture (id) {
      this.images.splice(id, 1)
      if (this.images.length > 0) {
        this.currentImage = this.images[0].url
      }
    },
    clear () {
      this.currentImage = ''
      Object.assign(this.post, {
        title: null,
        content: null,
        pictureList: null,
        url: 'https://',
        path: null
      })
      this.images = []
      this.uploadFile = []
    },

    createPost () {
      this.loading = true
      const formData = new FormData()
      this.images.forEach((image) => {
        formData.append('uploadFiles', image.uploadFile)
      })
      const json = JSON.stringify(this.post)
      const blob = new Blob([json], {
        type: 'application/json'
      })
      formData.append('postDS', blob)
      postAPI.createPost(formData).then((res) => {
        if (res && res.data !== -1) {
          alert('上傳成功')
        } else {
          alert('上傳失敗，請重試嘗試，可能原因：\n1.請檢查各欄位\n2.目前支援照片格式：JPEG\n3.單一檔案大小限制：20MB\n4.您上傳的收藏品數量已大於您購買的收藏品數量')
        }
        this.clear()
        this.loading = false
      })
    }
  }
}
</script>
<style scoped>
.imageItem {
  transition: all 0.15s;
  cursor: pointer;
}
.imageItem:hover {
  transform: translate(0, -3px);
}
.bg-create-post{
  background-image: url('~@/assets/background/profile_bg.png');
  background-position: top;
  background-size: cover;
}
.loader {
  border: 14px solid #9C9C9C; /* Light grey */
  border-top: 14px solid #C2321F; /* Blue */
  border-radius: 50%;
  width: 100px;
  height: 100px;
  animation: spin 2s linear infinite;
}
*:focus {
    outline: none;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.img-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-column-gap: 10px;
}

.v-input__prepend-outer{
  margin: 0px !important;
  padding: 0px  !important;
}

.v-input.upload-icon.ml-2.p-0.justify-center.items-center.theme--light.v-text-field.v-text-field--is-booted.v-file-input{
  transform: scale(1.3) !important;
  margin: 0px 0px -2px 10px !important;
}

</style>
