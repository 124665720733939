<template lang="pug">
.flex.flex-col.h-full.relative
  .chatTitle.flex.items-center.px-6.py-3.shadow
    .mr-5
      img.rounded-full.object-cover.w-14.h-14(:src="current.receiverAvatar")
    .title.text-lg {{ current.receiverName }}
    .flex-1
    .icon.cursor-pointer(@click="goToProfile" v-if="current.receiverAvatar")
      i.fas.fa-external-link-alt
  .flex.flex-col.justify-between.relative(ref='chatContent' style='overflow: auto; flex:1; background-color: #FBFBFB;')
    .chats.h-full.p-8
      .chat(v-for='item in chatList')
        chat-card-r(:imgSrc='current.receiverAvatar' :chat='item' v-if='current.receiver != item.sender' :key="'r'+item.id")
        chat-card-l(:chat='item' v-else-if='current.receiver == item.sender' :key="'l'+item.id")
  .flex.w-full.items-center.p-4.relative(:style="{display:current.receiverAvatar?'':'none'}")
    .input.bg-gray-100.p-2.rounded.mr-4.w-full
      input.w-full(ref="sendMsg" v-model='message' placeholder="訊息只保留30天，請不要在訊息內討論重要資料" style="" @keydown.stop.prevent.enter="createChat")
    .text.w-5.h-5(@click='createChat')
      img.w-5.h-5(src="@/assets/icons/send_icon.png")
</template>
<script>
import ChatCardR from './ChatCardR.vue'
import ChatCardL from './ChatCardL.vue'

import chatAPI from '../../../apis/chat'

export default {
  components: { ChatCardL, ChatCardR },
  name: 'ChatDialogue',
  props: ['current', 'chatFlag', 'chatDTO'],
  mounted () {
    this.message = this.$root.$data.state.chat.takePostInfoToChat
    this.$root.$data.settakePostInfoToChat('')
  },
  data () {
    return {
      message: '',
      chatList: [],
      page: 0,
      idLimit: 0
    }
  },
  watch: {
    current: function (newData, oldData) {
      this.$refs.chatContent.removeEventListener('scroll', this.handleScroll)
      this.chatList = []
      this.page = 0
      this.idLimit = 0
      this.getChat().then(() => {
        if (this.chatList.length > 0) { this.idLimit = this.chatList[this.chatList.length - 1].id }
        this.scrollToBottom()
        this.$refs.chatContent.addEventListener('scroll', this.handleScroll)
      })
    },
    chatFlag: function (newData, oldData) {
      if (newData) {
        if (this.chatDTO.sender === this.current.receiver) { this.refreshChat() }
        this.$root.$data.setchatFlag(false)
      }
    }
  },
  methods: {
    clearMessage () {
      this.message = ''
    },
    handleScroll () {
      if (this.$refs.chatContent.scrollTop === 0) {
        this.page = this.page + 1
        this.getChat()
      }
    },
    scrollToBottom () {
      this.$nextTick(() => {
        this.$refs.chatContent.scrollTop = this.$refs.chatContent.scrollHeight
      })
    },
    goToProfile () {
      this.$router.push(`/profile/${this.current.nickName}`)
    },
    getChat () {
      const chatDS = {
        receiver: this.current.receiver,
        page: this.page,
        size: 20,
        idLimit: this.idLimit
      }
      return chatAPI.findChat(chatDS).then((response) => {
        const data = response.data
        data.reverse()
        this.chatList.unshift(data)
        this.chatList = this.chatList.flat()
      })
    },
    async createChat () {
      if (this.message !== '') {
        const newChat = {
          receiver: this.current.receiver,
          message: this.message
        }
        await chatAPI.create(newChat)
        this.clearMessage()
        await this.refreshChat()
        this.$emit('toChatListTop', this.current)
      }
    },
    async refreshChat () {
      const chatDS = {
        receiver: this.current.receiver,
        page: 0,
        size: 1,
        idLimit: 0
      }
      return chatAPI.findChat(chatDS).then((response) => {
        this.chatList.push(response.data)
        this.chatList = this.chatList.flat()
        this.scrollToBottom()
      })
    }
  }
}
</script>
<style scoped>
.absolute {
  position: absolute;
  bottom: 0;
  width: 70%;
}

.chatTitle{
  background-image: url('~@/assets/background/chat_title_bg.png');
  background-position: center;
  background-size: cover;
}

.input input{
    background-color: transparent;
    border: 0px solid;
}
*:focus {
    outline: none;
}
</style>
