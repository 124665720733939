<template>
  <div class="h-full">
    <v-window v-model="step" class="h-full">
        <v-window-item :value="1" class="h-full">
          <login @nextPage="setStep" class="h-full"></login>
        </v-window-item>
        <v-window-item :value="2" class="h-full">
          <register @nextPage="setStep(1)" class="h-full"></register>
        </v-window-item>
        <v-window-item :value="3" class="h-full">
          <forget-password @nextPage="setStep(1)" class="h-full"></forget-password>
        </v-window-item>
      </v-window>
  </div>
</template>

<script>
import Login from './LoginAndRegister/Login'
import Register from './LoginAndRegister/Register'
import ForgetPassword from './LoginAndRegister/ForgetPassword'

export default {
  name: 'LoginAndRegister',
  components: {
    Login,
    Register,
    ForgetPassword
  },
  props: ['defaultStep'],
  data () {
    return {
      step: parseInt(this.defaultStep) ?? 2
    }
  },
  mounted () {
  },
  methods: {
    setStep (step) {
      this.step = step
    }
  }
}
</script>
