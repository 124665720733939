
const serverIP = {
  backEndServer: 'https://tomcat.collectorscollection.com.tw/CollectionSharingWEB',
  frontEndServer: 'https://collectorscollection.com.tw'
}

/*
const serverIP = {
  backEndServer: 'https://tomcat.everythingpossiable.dynu.net/CollectionSharingWEB',
  frontEndServer: 'http://everythingpossiable.dynu.net:7686'
}
*/
/*
const serverIP = {
  backEndServer: 'http://localhost:8080',
  frontEndServer: 'http://localhost:8081'
}
*/
export default serverIP
