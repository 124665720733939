<template lang="pug">
.min-h-full.w-screen.flex.flex-col.justify-center.items-center
  .fixed.about-bg.w-full.h-full
  v-card.rounded-2xl.bg-white.flex.flex-col.p-8(class="w-10/12", style="max-width:600px")
    .flex.justify-center.items-center 關於我們
    .flex.items-center 聯絡時間：周一至周五 09:00-17:00
    .flex.items-center 客服電話：0981-286-889
    .flex.items-center 客服信箱：damai.collectorscollection@gmail.com
</template>
<script>
export default {
  name: 'About',
  data () {
    return {
    }
  },
  mounted () {
  },
  methods: {
  }
}
</script>

<style scoped>
.about-bg{
  background-image: url('~@/assets/background/profile_bg.png');
  background-position: top;
  background-size: cover;
}
</style>
