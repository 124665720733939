import requestHelper from './helper'

import axios from 'axios'
import serverIP from './common'

const userURL = '/user'
const oauth2URL = `${serverIP.backEndServer}/oauth/token?grant_type=password&client_id=61308240692794887266&client_secret=WtDG7CSJSswnLrxo0V2k&scope=all&`

const userAPI = {
  login: user => (axios.post(`${oauth2URL}username=${user.username}&password=${user.password}`)),
  registrationVerification: registerDTO => (axios.post(`${serverIP.backEndServer}/user/registrationVerification`, registerDTO)),
  localRegister: registerDTO => (axios.post(`${serverIP.backEndServer}/user/localRegister`, registerDTO)),
  reviseProfile: profile => (requestHelper.post(`${userURL}/reviseProfile`, profile)),
  revisePassword: changePasswordDTO => (requestHelper.post(`${userURL}/revisePassword`, changePasswordDTO)),
  getProfile: userID => (axios.get(`${serverIP.backEndServer}${userURL}/profile/${userID}`)),
  forgetPassword: email => (axios.post(`${serverIP.backEndServer}/user/forgetPassword?email=${email}`)),
  changePasswordForForget: changePassword => (axios.post(`${serverIP.backEndServer}/user/changePasswordForForget`, changePassword)),
  updateAvatar: formData => (requestHelper.post(`${userURL}/updateAvatar`, formData)),
  getALLUsernameAndRole: () => (requestHelper.get(`${userURL}/getALLUsernameAndRole`)),
  isPaidUser: () => (requestHelper.get(`${userURL}/isPaidUser`)),
  loginFaceBook: code => (axios.get(`${serverIP.backEndServer}/user/loginFaceBook?code=${code}`)),
  loginGoogle: code => (axios.get(`${serverIP.backEndServer}/user/loginGoogle?code=${code}`)),
  getUserIDByNickname: nickName => (axios.get(`${serverIP.backEndServer}/user/getUserIDByNickname?nickName=${nickName}`)),
  getUserID: () => (requestHelper.get(`${userURL}/getUserID`)),
  getProfileListByUserIDList: userIDList => (requestHelper.post(`${userURL}/getProfileListByUserIDList`, userIDList))
}

export default userAPI
