<template>
  <v-menu offset-y min-width="245px">
    <template v-slot:activator="{ on, attrs }">
      <div  v-bind="attrs" v-on="on" class="bg-white rounded flex justify-center items-center w-9 h-9 " :class="border ? 'border':''">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <img v-bind="attrs" v-on="on" src="@/assets/home/share_icon.png" class="w-5 h-5"/>
          </template>
          <span>分享</span>
        </v-tooltip>
      </div>
    </template>
    <v-list>
      <v-list-item>
        <v-list-item-title>
          <v-btn :href="`https://www.facebook.com/sharer/sharer.php?u=${url}${postID}`" target="_blank" icon x-large>
            <v-avatar size="56">
              <v-icon color="blue"> mdi-facebook </v-icon>
            </v-avatar>
          </v-btn>
          <v-btn :href="`https://social-plugins.line.me/lineit/share?url=${url}${postID}`" target="_blank" icon x-large>
            <v-avatar size="56">
              <v-icon color="green"> fab fa-line </v-icon>
            </v-avatar>
          </v-btn>
          <v-btn :href="`http://pinterest.com/pin/create/button/?url=${url}${postID}&media=&description=`" target="_blank" icon x-large>
            <v-avatar size="56">
              <v-icon color="red"> mdi-pinterest </v-icon>
            </v-avatar>
          </v-btn>
          <v-btn @click="copy(`${url}${postID}`)" icon x-large>
            <v-avatar size="56">
              <v-icon color="gray"> mdi-link-variant </v-icon>
            </v-avatar>
          </v-btn>
        </v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>
<script>
import serverIP from '../../../apis/common'

export default {
  name: 'SharePost',
  props: {
    postID: {},
    border: {
      default: false
    }
  },
  data () {
    return {
      url: `${serverIP.frontEndServer}/post/`
    }
  },
  mounted () {},
  methods: {
    copy (text) {
      navigator.clipboard.writeText(text).then(() => alert('success')).catch(() => alert('fail'))
    }
  }
}
</script>
