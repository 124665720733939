<template lang="pug">
.min-h-screen.h-full.w-screen.flex.flex-col
  .fixed.profile-bg.w-full.h-full
  .m-8.relative(class="md:mx-16")
    v-row.text-center
      profile-head(:profile='profile' :userID='$root.$data.state.common.userID' :readUserID='readUserID' @toChat='toChat')
    v-row(v-if='!isOriginalPath')
      v-spacer
      v-menu(v-if='$root.$data.state.common.isLogin && $root.$data.state.common.userID===readUserID' offset-y='')
        v-list
          v-list-item-group
            create-directory(@refreshDirectory='readDirectoryAndClear' :currentPath='currentPath.path')
            v-list-item(v-if="!currentPath.path.startsWith('home/track') && paidUser" @click='createPost')
              v-list-item-content
                v-list-item-title(@click='createPost') &#x65B0;&#x589E;&#x8CBC;&#x6587;
            v-list-item(v-if="!(!currentPath.path.startsWith('home/track') && paidUser)" @click='createPost')
              v-list-item-content
                v-list-item-title(@click='toPaidPlan') &#x65B0;&#x589E;&#x8CBC;&#x6587;(&#x4ED8;&#x8CBB;&#x529F;&#x80FD;)
    v-row
      v-col.py-2(cols='12')
        .flex.items-center
          .mr-2.cursor-pointer(elevation='2' @click='previousPage' v-if='!isOriginalPath')
            v-icon(color='black') mdi-arrow-left
          profile-head-in-directory(v-if='!isOriginalPath' :currentPath='currentPath' :share='share' @deletedirectory='deleteDirectory')
          .flex.flex-col.cursor-pointer(v-if='isOriginalPath')
            .text-base.font-Sans.font-medium(@click='goToDirectory("home/upload")') 收藏品 {{postsCount}}
            .underline.w-full.h-1.bg-red-500(v-if="this.currentPath.path === 'home/upload'", style="background-color: #C2321F;")
          .w-5(v-if='isOriginalPath')
          .flex.flex-col.cursor-pointer(v-if="isCurrentUser && isOriginalPath")
            .text-base.font-Sans.font-medium(@click='goToDirectory("home/track")') 最愛收藏
            .underline.w-full.h-1.bg-red-500(v-if="this.currentPath.path === 'home/track'" style="background-color: #C2321F;")
          .flex-1
          .flex(v-if="isCurrentUser")
            create-directory(
              @refreshDirectory="readDirectoryAndClear"
              :currentPath="currentPath.path"
              :btnStyle='true'
            )
            .w-2
            .btn.bg-pri.flex(v-if="!currentPath.path.startsWith('home/track')" @click="createPost")
              v-icon(color="white") mdi-plus
              span 新增藏品
        .flex.mt-7.overflow-x-auto
          .flex.mr-4(v-for="(directory, index) in directorys")
            .imgs.flex.flex-col(v-if="queryPost && queryPost[directory.name]")
              .folder(@click="nextDirectory(directory.name)")
               ProfileImageFolder(:posts="[...queryPost[directory.name]]" :key="`ProfileImageFolder-${index}-${directory.name}`")
              .flex.items-center.mt-4.mb-2
                .font-bold.text-lg {{directory.name}}
                .w-4
                ReviseDirectory(:key="`ReviseDirectory-${index}-${directory.name}`", :currentPath='currentPath.path', :directoryID='directory.data', :directoryName='directory.name' @readDirectory='readDirectoryAndClear' v-if="isCurrentUser")
        v-divider.mt-6ReviseDirect
        .mt-6.flex.justify-center
          masonry-image(:posts='posts' :currentPath='currentPath.path' :readUserID='readUserID' @readDirectoryAndClear='readDirectoryAndClear' @imgClick='imgClick' @readDirectory='readDirectoryAndClear')
</template>
<script>
import MasonryImage from '../Common/Masonry/MasonryCustom.vue'
import CreateDirectory from '../Common/Common/CreateDirectory.vue'
import ReviseDirectory from './Profile/ReviseDirectory.vue'
import ProfileHead from './Profile/ProfileHead.vue'
import ProfileHeadInDirectory from './Profile/ProfileHeadInDirectory.vue'
import ProfileImageFolder from './Profile/ProfileImageFolder.vue'
import userAPI from '../../apis/user'
import postAPI from '../../apis/post'
import directoryAPI from '../../apis/directory'

export default {
  name: 'Profile',
  components: {
    MasonryImage,
    CreateDirectory,
    ReviseDirectory,
    ProfileHead,
    ProfileHeadInDirectory,
    ProfileImageFolder
  },
  data () {
    return {
      profile: {
        photoURL: '',
        firstName: '',
        lastName: '',
        introduction: '',
        siteURL: ''
      },
      currentPath: {
        path: 'home/upload',
        userID: '',
        readUserID: '',
        page: 0,
        size: 12
      },
      queryPost: {},
      originalPath: 'home/upload',
      directorys: [],
      directoryInputDS: {
        path: '',
        directoryName: '',
        share: false
      },
      share: false,
      paidUser: false,
      readUserID: '',
      posts: [],
      nickName: '',
      postsCount: 0,
      debouncefunc: null
    }
  },
  computed: {
    isOriginalPath () {
      return this.currentPath.path === 'home/upload' || this.currentPath.path === 'home/track'
    },
    isCurrentUser () {
      return this.$root.$data.state.common.isLogin && this.$root.$data.state.common.userID === this.readUserID
    }
  },
  async mounted () {
    this.nickName = this.$route.params.nickName
    await this.getUserIDByNickname()
    this.debouncefunc = this.debounce(this.handleScroll)
    window.addEventListener('scroll', this.debouncefunc)
    this.getProfile()
    if (this.$root.$data.state.common.userID !== this.readUserID) {
      this.currentPath.path = 'home/upload'
      this.originalPath = 'home/upload'
    }
    this.currentPath.userID = this.$root.$data.state.common.userID
    this.currentPath.readUserID = this.readUserID
    this.readDirectory()
    this.isPaidUser()
    this.countUserPost()
  },
  beforeDestroy () {
    window.removeEventListener('scroll', this.debouncefunc)
  },
  methods: {
    handleScroll () {
      const scrollHeight = window.scrollY
      const maxHeight =
        window.document.body.scrollHeight -
        window.document.documentElement.clientHeight - 30
      if (scrollHeight >= maxHeight * 1) {
        this.currentPath.page += 1
        this.readOnlyDirectory()
      }
    },
    debounce (func, delay = 250) {
      let timer = null
      return () => {
        const context = this
        const args = arguments

        clearTimeout(timer)
        timer = setTimeout(() => {
          func.apply(context, args)
        }, delay)
      }
    },
    toChat () {
      this.settakeDataToChat()
      this.$router.push('/chat')
    },
    createPost () {
      if (this.paidUser) {
        this.$router.push('/create_post')
      } else {
        if (window.confirm('您還未成為會員，是否前往訂閱？')) {
          this.toPaidPlan()
        }
      }
    },
    toPaidPlan () {
      this.$router.push('/paid_plan')
    },
    goToDirectory (dirName) {
      this.currentPath.path = dirName
      this.readDirectoryAndClear()
    },
    nextDirectory (dirName) {
      this.currentPath.path = this.currentPath.path + '/' + dirName
      this.readDirectoryAndClear()
    },
    previousPage () {
      let newPath = ''
      if (this.currentPath.path === 'home' || this.currentPath.path === 'home') {
        return
      }
      const temp = this.currentPath.path.split('/')
      for (let i = 0; i < temp.length - 1; i++) {
        newPath += temp[i] + '/'
      }
      this.currentPath.path = newPath.substr(0, newPath.length - 1)
      this.readDirectoryAndClear()
    },
    imgClick (id) {
      this.$router.push(`/post/${id}`)
    },
    readDirectoryAndClear () {
      this.currentPath.page = 0
      this.currentPath.size = 12
      this.posts = []
      this.readDirectory()
      this.countUserPost()
    },

    settakeDataToChat () {
      this.$root.$data.settakeDataToChat({
        receiver: this.readUserID,
        newMessageCount: 0,
        time: new Date()
      })
    },
    async getUserIDByNickname () {
      await userAPI.getUserIDByNickname(this.nickName).then((response) => {
        this.readUserID = response.data
      })
    },
    getProfile () {
      userAPI.getProfile(this.readUserID).then((response) => {
        this.profile = response.data
      })
    },
    getDirectoryData (queryDirectImage, path) {
      directoryAPI.readDirectory(queryDirectImage).then((response) => {
        const data = response.data
        this.$set(this.queryPost, path,
          data
            .filter((directory) => directory.type === 'file')
            .map(directory => {
              return {
                id: directory.data,
                pictureURL: directory.pictureURL,
                fileID: directory.fileID
              }
            }
            ))
      })
    },
    readOnlyDirectory () {
      directoryAPI.readDirectory(this.currentPath).then((response) => {
        const data = response.data
        this.directorys = data.filter((directory) => directory.type === 'dir')
        data
          .filter((directory) => directory.type === 'file')
          .map((directory) => {
            this.posts.push({
              id: directory.data,
              pictureURL: directory.pictureURL,
              fileID: directory.fileID
            })
          })
      })
    },
    readDirectory () {
      directoryAPI.readDirectory(this.currentPath).then((response) => {
        const data = response.data
        this.directorys = data.filter((directory) => directory.type === 'dir')
        data
          .filter((directory) => directory.type === 'file')
          .map((directory) => {
            this.posts.push({
              id: directory.data,
              pictureURL: directory.pictureURL,
              fileID: directory.fileID
            })
          })
      }).then(() => {
        const currentPath = this.currentPath.path
        const queryDirectImage = {
          path: currentPath,
          userID: this.currentPath.userID,
          readUserID: this.currentPath.readUserID,
          page: 0,
          size: 3
        }
        this.directorys.forEach(element => {
          queryDirectImage.path = currentPath + '/' + element.name
          this.getDirectoryData(queryDirectImage, element.name)
        })
      })
    },
    isPaidUser () {
      if (this.$root.$data.state.common.isLogin) {
        userAPI.isPaidUser(this.$root.$data.state.common.userID).then((response) => {
          this.paidUser = response.data
        })
      }
    },
    createDirectory () {
      this.directoryInputDS.path = this.currentPath.path
      directoryAPI.createDirectory(this.directoryInputDS).then(() => {
        this.directoryInputDS.path = ''
        this.directoryInputDS.directoryName = ''
        this.directoryInputDS.share = false
        this.readDirectory()
      })
    },
    deleteDirectory (path) {
      if (path !== this.currentPath.path) {
        path = this.currentPath.path + '/' + path
      }
      const request = {
        path: path
      }
      directoryAPI.deleteDirectory(request).then(() => {
        this.previousPage()
      })
    },
    countUserPost () {
      postAPI.countUserPost(this.readUserID).then((response) => {
        this.postsCount = response.data
      })
    }
  }
}
</script>

<style>
.profile-bg{
  left: 0;
  top: 0;
  overflow: hidden;
  background-image: url('~@/assets/background/profile_bg.png');
  background-position: top;
  background-size: cover;
}

</style>
