<template lang="pug">
.chat.flex.justify-end.items-end.mb-6
  .chatTime.text-gray-400.mr-1.text-xs {{chatTime}}
  .chatContent.p-2.text-white.rounded(style="background-color: #828282;word-break:break-all;") {{ chat.message }}
</template>
<script>
export default {
  name: 'ChatCardLeft',
  props: ['chat'],
  data () {
    return {}
  },
  computed: {
    chatTime: function () {
      const date = new Date(this.chat.time)
      return `${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()} ${date.getHours()}:${date.getMinutes()}`
    }
  }
}
</script>
