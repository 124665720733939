<template>
  <v-dialog v-model="dialog" max-width="344">
    <template v-slot:activator="{ on, attrs }">
      <div  v-bind="attrs" v-on="on" class="bg-white rounded flex justify-center items-center w-9 h-9 " :class="border ? 'border':''">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <img v-bind="attrs" v-on="on" src="@/assets/home/report_icon.png" class="w-5 h-5" style="object-fit: contain;"/>
          </template>
          <span>檢舉</span>
        </v-tooltip>
      </div>
    </template>

    <v-card>
      <v-card-title class="headline">檢舉</v-card-title>
      <div class="text-base ml-6">
        此內容是否違反了我們的<a @click.prevent="$router.push('/privacy')">《會員條款》？</a><br>
        我們的<a @click.prevent="$router.push('/privacy')">《會員條款》</a>說明在 藏家會 上允許和禁止出現的內容。我們會在專家的協助下定期檢閱和更新<a @click.prevent="$router.push('/privacy')">《會員條款》。</a>
      </div>
      <v-card-actions>
        <v-spacer></v-spacer>
        <div class="btn min-w-max bg-pri mr-4" @click="createReportForPost">是</div>
        <div class="btn min-w-max" @click="close">否</div>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import postAPI from '../../../apis/post'

export default {
  name: 'Report',
  props: {
    postID: {},
    border: {
      default: false
    }
  },
  data () {
    return {
      dialog: false
    }
  },
  methods: {
    close () {
      this.dialog = false
    },

    createReportForPost () {
      const reportDS = {
        postID: this.postID
      }
      postAPI.createReportForPost(reportDS).then(() => {
        this.close()
      })
    }
  }
}
</script>
