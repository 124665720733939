<template lang="pug">
.flex.flex-col.bg-color
  .home_bg.w-screen.h-screen.flex.justify-center.items-center.relative
    .flex.flex-col.items-center.mt-10
      img.home-logo(src="@/assets/home/home_bg_1.png")
      h2.h2 探索珍貴藏品
      .text.max-w-lg.font-Serif.font-medium.mt-4 我們是一個古董藝術品分享平台，旨在連接全球的古董藝術品愛好者，一同分享、探索和學習各個時期和地區的古董藝術品，促進藝術文化的交流和欣賞。
      img.w-4.h-4.fixed.bottom-4.z-10.load-more(src="@/assets/load_more.png" :class="{'hidden': hiddenScrollHint}")
    .absolute.max-w-lg.top-10
  .flex.flex-col.items-center.py-9.relative
    .font-Serif.font-bold.text-xl.bottom-2 會友收藏品
    .font-Serif.text-sm Collections
    .absolute.left-0.top-0.px-8.py-9.w-full
      .home_decoration_l.h-28
    .absolute.left-0.top-0.px-8.py-9.w-full
      .home_decoration_r.h-28
  .px-4.flex.justify-center(class="md:px-16")
    KeepAlive
      masonry-image(:posts="$root.$data.state.post.postsForHome" currentPath readUsername @imgClick="imgClick")
</template>

<script>
import MasonryImage from '../Common/Masonry/MasonryCustom.vue'
import postAPI from '../../apis/post'

export default {
  name: 'Home',
  components: { MasonryImage },
  data () {
    return {
      debouncefunc: null,
      currentScrollH: 0,
      scrollH: 0,
      hiddenScrollHint: false
    }
  },
  created () {
    this.scrollEvent()
  },
  mounted () {
    this.debouncefunc = this.debounce(this.handleScroll)
    window.addEventListener('scroll', this.debouncefunc)
    if (this.$root.$data.state.post.postsForHome.length === 0) { this.getRandomPost() }
  },
  beforeDestroy () {
    window.removeEventListener('scroll', this.debouncefunc)
  },
  methods: {
    handleScroll () {
      const scrollHeight = window.scrollY
      const maxHeight =
        window.document.body.scrollHeight -
        window.document.documentElement.clientHeight - 30
      if (scrollHeight >= maxHeight * 1) {
        this.getRandomPost()
      }
    },
    debounce (func, delay = 250) {
      let timer = null
      return () => {
        const context = this
        const args = arguments

        clearTimeout(timer)
        timer = setTimeout(() => {
          func.apply(context, args)
        }, delay)
      }
    },
    imgClick (id) {
      this.$router.push(`/post/${id}`)
    },
    getRandomPost () {
      postAPI.randomPost(18).then((response) => {
        response.data.map((post) => {
          this.$root.$data.setpostsForHome(post)
        })
      })
    },
    scrollEvent () {
      window.addEventListener('scroll', () => {
        this.currentScrollH = this.scrollH
        this.scrollH = window.scrollY
        if (this.currentScrollH < this.scrollH) {
          setTimeout(() => {
            this.hiddenScrollHint = true
          }, 1000)
        } else {
        }
      })
    }
  }
}
</script>
<style >
@import url('https://fonts.googleapis.com/css2?family=Bentham&family=Noto+Serif+TC:wght@200;400;600;900&family=Noto+Sans+TC:wght@100;300;400;500;700;900&display=swap');

.home_bg {
  background-image: url('~@/assets/home/bg.png');
  background-position: center;
  background-size: cover;
}
.home-logo {
  aspect-ratio: 876/500;
  width: 100%;
}
.home_collection {
  font-family: 'Bentham', serif;
  color:#C2321F;
  font-size: 148px;
  vertical-align: bottom;
  line-height: 100%;
  letter-spacing: 2%;
}
.home_space{
  letter-spacing: 4%;;
}

.font-Serif{
  font-family: 'Noto Serif TC', serif;
}
.font-Sans{
  font-family: 'Noto Sans TC', sans-serif;
}

.home_decoration_l {
  background-image: url('~@/assets/home_decoration_l.png');
  background-position: right;
  background-size: contain;
}
.home_decoration_r {
  background-image: url('~@/assets/home_decoration_r.png');
  background-position: left;
  background-size: contain;
}

.load-more{
  -webkit-animation: blink-2 1.5s cubic-bezier(0.550, 0.085, 0.680, 0.530) infinite alternate-reverse both;
  animation: blink-2 1.5s cubic-bezier(0.550, 0.085, 0.680, 0.530) infinite alternate-reverse both;
}

.h2{
  font-size: 1.5em;
  font-family: 'Noto Serif TC', serif;
  letter-spacing: 4px;
}
@media screen and (max-width: 768px) {
  .text.max-w-lg.font-Serif.font-medium.mt-4{
    width: 80%;
  }
}
</style>
