<template>
  <router-view :key="$route.fullPath"></router-view>
</template>

<script>
export default {
  name: 'App',
  mounted () {
  },
  destroyed () {
    this.$root.$data.webSocketDisConnect()
  },
  methods: {
  }
}
</script>

<style>
.v-application {
  /* font-family: 'Noto Sans TC', '微軟正黑體',sans-serif !important; */
  font-family: 'Noto Serif TC', serif !important;
}
.flex-1{
  flex: 1 1 0% !important;
}
</style>
