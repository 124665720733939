<template lang="pug">
v-menu(v-model='showMenu' offset-y='' :close-on-content-click='false')
  template(v-slot:activator='{ on, attrs }')
    v-btn(color='error' dark='' v-bind='attrs' v-on='on' outlined) 選擇目錄
  v-list(style='overflow-y:auto;height:460px')
    .absolute.right-2.top-2.z-50
        v-icon(@click="showMenu = false") mdi-close
    v-list-item
      .text-lg.font-medium 選擇收藏目錄
    v-list-item(v-if="currentPath.path != 'home/upload'")
      .flex.items-center.cursor-pointer(@click='previousPage')
          img.w-2.mr-2(src="@/assets/icons/last_page_icon.png")
          .mr-1 回到上一層
    v-list-item-content
      v-list-item-title
        .flex.justify-between.px-4
          .text-base {{ altedCurrentPath }}
          .w-32(class="md:w-40")
          .btn.bg-gray.mr-1.hover-pri(v-if="currentPath.path != 'home/upload'" @click='moveUploadFile(currentPath.path)') 移動
    v-list-item(v-for='(directory, index) in directorys' :key='index')
      .cursor-pointer.flex.justify-between.items-center.border.w-full.px-2.py-2(:class="{'rounded-t': index==0,'rounded-b': index==directorys.length-1}" @click='nextDirectory(directory.name)')
        .text-base {{ directory.name }}
        .w-32(class="md:w-40")
        .flex
          img.w-2.mr-2(style="object-fit:contain" src="@/assets/icons/next_page_icon.png" )
          .btn.bg-gray.mr-1.hover-pri.max-h-min(style="height:17.6" @click="moveUploadFile(currentPath.path+'/'+directory.name)") 移動
    create-directory(@refreshDirectory='readDirectory' :currentPath='currentPath.path')

</template>
<script>
import createDirectory from '../../../Common/Common/CreateDirectory.vue'
import directoryAPI from '../../../../apis/directory'

export default {
  components: { createDirectory },
  name: 'SelectDirectoryForUploadFile',
  props: ['fileID'],
  data () {
    return {
      showMenu: false,
      snackbar: false,
      directorys: [],
      currentPath: {
        path: 'home/upload',
        userID: '',
        readUserID: '',
        page: 0,
        size: 1
      },
      directoryInputDS: {
        path: '',
        fileID: ''
      }
    }
  },
  mounted () {
    this.currentPath.userID = this.$root.$data.state.common.userID
    this.currentPath.readUserID = this.$root.$data.state.common.userID
  },
  computed: {
    altedCurrentPath () {
      if (this.currentPath.path.startsWith('home/track')) {
        return `最愛收藏${this.currentPath.path.split('home/track')[1]}`
      } else if (this.currentPath.path.startsWith('home/upload')) {
        return `收藏品${this.currentPath.path.split('home/upload')[1]}`
      }
      return this.currentPath.path
    }
  },
  watch: {
    showMenu: function (newDialog, oldDialog) {
      if (newDialog) { this.readDirectory() }
    }
  },
  methods: {
    nextDirectory (dirName) {
      this.currentPath.path = this.currentPath.path + '/' + dirName
      this.readDirectory()
    },
    previousPage () {
      let newPath = ''
      const temp = this.currentPath.path.split('/')
      for (let i = 0; i < temp.length - 1; i++) {
        newPath += temp[i] + '/'
      }
      this.currentPath.path = newPath.substr(0, newPath.length - 1)
      this.readDirectory()
    },

    readDirectory () {
      directoryAPI.readDirectory(this.currentPath).then((response) => {
        const data = response.data
        this.directorys = data.filter((directory) => directory.type === 'dir')
        this.directorys = this.directorys.slice()
      })
    },
    moveUploadFile (dirName) {
      this.directoryInputDS.path = dirName
      this.directoryInputDS.fileID = this.fileID
      directoryAPI.moveUploadFile(this.directoryInputDS).then((response) => {
        this.readDirectory()
        this.showMenu = false
        this.$emit('readDirectory')
        this.$emit('close')
      })
    }
  }
}
</script>
