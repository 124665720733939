<template>
    <div>登入中...</div>
</template>
<script>
import userAPI from '../../apis/user'

export default {
  name: 'ThirdPartyLogin',
  data () {
    return {
      code: '',
      provider: ''
    }
  },
  async mounted () {
    this.code = this.$route.query.code
    this.provider = this.$route.params.provider
    if (this.provider === 'fb') {
      let data = {}
      await this.loginFaceBook().then((response) => { data = response })
      if (data.result === 'true') {
        localStorage.setItem('user-token', data.access_token)
        const nowtime = new Date()
        localStorage.setItem('user-token-timeout', nowtime.setTime(nowtime.getTime() + (data.expires_in * 1000)))
        await this.init()
        this.$router.push('/home')
      } else {
        alert('請同意授權Email，方便通知')
        location.href = data.url
      }
    } else if (this.provider === 'google') {
      let data = {}
      await this.loginGoogle().then((response) => { data = response })
      if (data.result === 'true') {
        localStorage.setItem('user-token', data.access_token)
        const nowtime = new Date()
        localStorage.setItem('user-token-timeout', nowtime.setTime(nowtime.getTime() + (data.expires_in * 1000)))
        await this.init()
        this.$router.push('/home')
      } else {
        alert('請同意授權Email，方便通知')
        location.href = data.url
      }
    }
  },
  methods: {
    async init () {
      await this.$root.$data.init()
    },
    loginFaceBook () {
      return userAPI.loginFaceBook(this.code).then((response) => {
        return response.data
      })
    },
    loginGoogle () {
      return userAPI.loginGoogle(this.code).then((response) => {
        return response.data
      })
    }
  }
}
</script>
