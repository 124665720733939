<template>
    <v-snackbar :value="$root.$data.state.app.snackbarCustom.isOpen">
      {{ $root.$data.state.app.snackbarCustom.message }}
      <template v-slot:action="{ attrs }">
        <v-btn color="pink" text v-bind="attrs" @click="$root.$data.setSnackbarCustomIsOpenAction(false)">
          Close
        </v-btn>
      </template>
    </v-snackbar>
</template>

<script>
export default {
  name: 'SnackbarCustom'
}
</script>
