<template lang="pug">
.text-left.relative
  .flex.w-64.h-64.overflow-hidden.rounded(v-if="posts.length == 0")
    .flex-1
      img.object-cover.w-full.h-full(src="@/assets/home/home_bg_1.png")
  .flex.w-64.h-64.overflow-hidden.rounded(v-if="posts.length == 1")
    .flex-1
      img.object-cover.w-full.h-full(:src="posts[0].pictureURL")
  .flex.w-64.h-64.overflow-hidden.rounded(v-if="posts.length == 2")
    .flex-1
      img.object-cover.w-full.h-full(:src="posts[0].pictureURL")
    .w-1
    .flex-1
      img.object-cover.w-full.h-full(:src="posts[1].pictureURL")
  .flex.w-64.h-64.overflow-hidden.rounded(v-if="posts.length >= 3")
    .flex-1()
      img.object-cover.w-full.h-full(:src="posts[0].pictureURL")
    .w-1
    .flex-1.flex.flex-col(style="flex:1 !important")
      .x(style="height: 50%")
        img.object-cover.h-full.w-full(:src="posts[1].pictureURL")
      .h-1
      .x(style="height: 50%")
        img.object-cover.h-full.w-full(:src="posts[2].pictureURL")
</template>

<script>
export default {
  name: 'ProfileImageFolder',
  props: {
    posts: {
      default: () => []
    }
  },
  data () {
    return {
      searchText: null,
      isHomePage: false
    }
  }
}
</script>
