<template lang="pug">
.appBar.p-2.w-screen.absolute.z-50(:style="{position: isHomePage ? 'absolute':'relative', background: isHomePage? 'none':'white', boxShadow: isHomePage ? 'none':'0px 2px 5px rgba(0,0,0,0.1)'}" class="md:px-9")
  .flex.flex-row.w-full
    img.w-24.cursor-pointer.hidden(class="md:block" src="@/assets/logo.png" @click="toHome" style="object-fit:contain")
    img.w-7.cursor-pointer(class="md:hidden" src="@/assets/logo_no_txt.png" @click="toHome" style="object-fit:contain")
    .flex.justify-center.items-center(style="flex:1 !important")
      .search.text-center.bg-white.rounded-full.border.border-gray-100.flex.items-center.max-w-md.flex-1()
        .p-2
          img.w-5.h-5(src="@/assets/home/search_icon.png")
        input.search-input.w-full(placeholder=" 請輸入關鍵字搜尋 ", v-model="searchText" ,@keyup.enter="submitSearchText")
    .flex.justify-end.max-w-min(v-if="$root.$data.state.common.isLogin")
      div(v-if="$root.$data.state.common.isLogin").w-max.flex.items-center
        .cursor-pointer.flex.items-center.p-3
          v-badge(:content="$root.$data.state.app.newMessageCount", :value="$root.$data.state.app.newMessageCount", overlap, dot,color="pink")
            img.w-6(src="~/@/assets/home/message_icon.png" @click="toChat")
        v-menu(offset-y)
          template(v-slot:activator="{ on, attrs }")
            img.rounded-full.w-8.h-8(:src="$root.$data.state.common.profile.photoURL" , v-bind="attrs", v-on="on")
          v-card
            v-list
              v-list-item-group
                v-list-item(@click="toProfile")
                  v-list-item-avatar.rounded-full
                    img(:src="$root.$data.state.common.profile.photoURL")
                  v-list-item-content
                    v-list-item-title {{ $root.$data.state.username }}
                    v-list-item-subtitle
                      | {{
                      | `${$root.$data.state.common.profile.firstName} ${$root.$data.state.common.profile.lastName}`
                      | }}
                v-list-item(@click="toSetting")
                  v-list-item-content
                    v-list-item-title 設定
                v-list-item(@click="toPaidPlan")
                  v-list-item-content
                    v-list-item-title 付費方案
                v-list-item(@click="toPrivacy")
                  v-list-item-content
                    v-list-item-title 會員條款
                v-list-item
                  v-list-item-content(@click="logout")
                    v-list-item-title 登出
            v-divider

    .flex.justify-end.max-w-min(v-else="$root.$data.state.common.isLogin")
      .btn.min-w-max.bg-pri.mr-2(class="md:mr-4" @click="login(1)")  登入
      .btn.min-w-max(@click="login(2)")  註冊
</template>

<script>
import serverIP from '../../../apis/common'

export default {
  name: 'AppBar',
  data () {
    return {
      searchText: null,
      isHomePage: false
    }
  },
  created () {
    if (this.$route.path.includes('/home')) {
      this.isHomePage = true
    }
  },
  mounted () {
    if (this.$route.query.searchText) {
      this.searchText = this.$route.query.searchText
    }
  },
  methods: {
    toHerfHome () {
      location.href = `${serverIP.frontEndServer}/home`
    },
    toHome () {
      if (this.$router.currentRoute.path !== '/home') {
        this.$router.push('/home')
      }
    },
    submitSearchText () {
      this.$router.push({
        path: '/search',
        query: { searchText: this.searchText }
      })
    },
    login (step) {
      this.$router.push({
        path: '/login',
        query: { defaultStep: step }
      })
    },
    toChat () {
      if (this.$router.currentRoute.path !== '/chat') {
        this.$router.push('/chat')
      }
      this.$root.$data.clearNewMessageCountAction()
    },
    toProfile () {
      const nickName = this.$root.$data.state.common.profile.nickName
      if (this.$router.currentRoute.path !== '/profile/' + nickName) {
        this.$router.push('/profile/' + nickName)
      }
    },
    toSetting () {
      if (this.$router.currentRoute.path !== '/setting') {
        this.$router.push('/setting')
      }
    },
    toPaidPlan () {
      if (this.$router.currentRoute.path !== '/paid_plan') {
        this.$router.push('/paid_plan')
      }
    },
    toPrivacy () {
      if (this.$router.currentRoute.path !== '/privacy') {
        this.$router.push('/privacy')
      }
    },
    logout () {
      this.$root.$data.close()
      this.$router.push('/login')
    }
  }
}
</script>

<style scoped>
input:focus-visible {
  outline: none;
}
.message_icon{
  background-image: url('~@/assets/home/message_icon.png');
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  cursor: pointer;
 }

 *:focus {
    outline: none;
}
</style>
