<template>
  <v-app>
    <AppBar></AppBar>
    <Content></Content>
    <Footer></Footer>
    <snackbar-custom :isOpen="true" :message="'Yes'"></snackbar-custom>
  </v-app>
</template>

<script>
import SnackbarCustom from '../Common/SnackbarCustom.vue'
import AppBar from './AppBar'
import Content from './Content'
import Footer from './Footer'

export default {
  name: 'SysTemplate',
  components: {
    AppBar, Content, Footer, SnackbarCustom
  },
  methods: {

  }
}
</script>
