<template lang="pug">
v-row
  v-col(cols='12')
    v-card-text.mt-12.flex.flex-col(class="md:px-56")
      .font-bold.text-3xl 忘記密碼
      .text-red-500.cursor-pointer.mb-10 使用你註冊的電子郵件
      .flex.mb-3.flex-col(class="md:flex-row")
        input.login-input.rounded-sm.bg-white.px-3.py-2(v-model='email' placeholder='電子郵件')
        .w-2.h-2
        .btn-pri.rounded.confirm-code-btn.cursor-pointer(@click='forgetPassword') 寄送驗證碼
      input.mb-3.login-input.rounded-sm.bg-white.px-3.py-2(v-model='changePassword.checkCode' placeholder='驗證碼')
      input.mb-3.login-input.rounded-sm.bg-white.px-3.py-2(:append-icon="passwordVisible ? 'mdi-eye' : 'mdi-eye-off'" :type="passwordVisible ? 'text' : 'password'" @click:append='passwordVisible = !passwordVisible' v-model='changePassword.firstPassword' placeholder='輸入密碼')
      input.mb-3.login-input.rounded-sm.bg-white.px-3.py-2(:append-icon="passwordVisible ? 'mdi-eye' : 'mdi-eye-off'" :type="passwordVisible ? 'text' : 'password'" @click:append='passwordVisible = !passwordVisible' v-model='changePassword.secondPassword' placeholder='再次輸入密碼')
    .text-center.mt-3.mb-3.flex.justify-center
      .btn.bg-pri.mr-3(@click='changePasswordForForget') 更改密碼
      .btn(@click="$emit('nextPage')") 返回
</template>
<script>
import userAPI from '@/apis/user'

export default {
  name: 'ForgetPassword',
  data () {
    return {
      email: null,
      passwordVisible: false,
      changePassword: {
        checkCode: null,
        firstPassword: null,
        secondPassword: null
      }
    }
  },
  methods: {
    forgetPassword () {
      userAPI.forgetPassword(this.email).then((response) => {
        if (response.data === true) {
          alert('驗證碼已寄到您的信箱，請查看您的信箱')
        } else {
          alert('傳送失敗，可能原因：\n1.電子郵件格式錯誤\n2.同一電子郵件30分鐘內，只能傳送一次驗證碼')
        }
      })
    },
    changePasswordForForget () {
      userAPI.changePasswordForForget(this.changePassword).then((response) => {
        if (response.data === true) {
          alert('更改成功')
          this.$emit('nextPage')
        } else {
          alert('更改失敗，可能原因：\n1.密碼輸入二次不同\n2.密碼需要包含大小寫英文和數字')
        }
      })
    }
  }
}
</script>
