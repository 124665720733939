<template lang="pug">
.px-4.flex.justify-center(class="md:px-16")
  .fixed.profile-bg.w-full.h-full
  masonry-image.mt-8(:posts="posts" currentPath="" readUserID="" @imgClick="imgClick")
</template>

<script>
import MasonryImage from '../Common/Masonry/MasonryCustom.vue'

import postAPI from '../../apis/post'

export default {
  name: 'Search',
  props: ['searchText'],
  components: { MasonryImage },
  data () {
    return {
      posts: [],
      snackbar: false,
      message: '',
      numberOfPage: 0,
      postInputDS: {
        searchText: this.searchText,
        from: 0,
        size: 5
      },
      debouncefunc: null
    }
  },
  mounted () {
    this.debouncefunc = this.debounce(this.handleScroll)
    window.addEventListener('scroll', this.debouncefunc)
    this.postInputDS.searchText = this.searchText
    this.getSearchPost()
  },
  beforeDestroy () {
    window.removeEventListener('scroll', this.debouncefunc)
  },
  watch: {
    searchText: function (newData, oldData) {
      this.posts = []
      this.postInputDS.searchText = newData
      this.getSearchPost()
    }
  },
  methods: {
    handleScroll () {
      const scrollHeight = window.scrollY
      const maxHeight =
        window.document.body.scrollHeight -
        window.document.documentElement.clientHeight - 30
      if (scrollHeight >= maxHeight * 1) {
        if (this.numberOfPage !== 0) {
          this.postInputDS.from +=
            this.postInputDS.from + this.postInputDS.size
          this.getSearchPost()
        } else {
          this.$root.$data.state.app.snackbarCustom.isOpen = true
          this.$root.$data.state.app.snackbarCustom.message = '資料已到底'
        }
      }
    },
    debounce (func, delay = 250) {
      let timer = null
      return () => {
        const context = this
        const args = arguments

        clearTimeout(timer)
        timer = setTimeout(() => {
          func.apply(context, args)
        }, delay)
      }
    },
    imgClick (id) {
      this.$router.push(`/post/${id}`)
    },

    getSearchPost () {
      postAPI.searchPost(this.postInputDS).then((response) => {
        this.numberOfPage = response.data.length
        if (this.numberOfPage !== 0) {
          response.data.map((post) => {
            this.posts.push({
              id: post.id,
              pictureURL: post.pictureList[0].pictureURL
            })
          })
        } else {
          this.$root.$data.state.app.snackbarCustom.snackbar = true
          this.$root.$data.state.app.snackbarCustom.message = '查無資料'
        }
      })
    }
  }
}
</script>
<style>
.profile-bg{
  left: 0;
  top: 0;
  overflow: hidden;
  background-image: url('~@/assets/background/profile_bg.png');
  background-position: top;
  background-size: cover;
}

</style>
