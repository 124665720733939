<template lang="pug">
.div
  .fixed.profile-bg.w-full.h-full
  .h-8
  .flex.flex-col.items-center.relative
    v-row.absolute.left-8
      v-col(cols='12')
        v-btn.mr-2(icon='' @click='$router.go(-1)')
          v-icon mdi-arrow-left
    v-card.elevation-12.pa-5(class="md:w-4/6" style="max-width:1280px")
      .flex.flex-col(class="md:flex-row")
        v-col.p-0
          .flex.flex-col.p-0.max-w-full(cols='6')
            .flex.justify-center.items-center.border.rounded.shadow-sm.w-full.relative.bg-black(style="aspect-ratio: 1")
              ZoomImage(:src='currentImage',
                class="zoom-image"
                :options="{paneContainer: '#zoomContainer' ,inlinePane: false, zoomFactor:2}"
              )
              .absolute#zoomContainer.w-full.h-full.z-50.pointer-events-none
          .flex.p-0.mt-2.w-full.flex-wrap
            template(v-for='(image, index) in post.pictureList')
              div(:key='index' style="aspect-ratio:1")
                img.h-12.rounded.mr-2.mb-2(:id='index'  :src='image.pictureURL' @click='imageClick(index)' style='object-fit: cover;aspect-ratio:1'  )
        v-col.relative.box-border
          v-row
            v-col
              div
                v-avatar(size='32')
                  img(:src='postUserProfile.photoURL')
                v-btn.px-2(text='' @click='authorClick(postUserProfile.nickName)') {{postUserProfile.firstName + ' ' + postUserProfile.lastName }}
          v-row.absolute.right-5.top-0
            v-row
              SharePost(:postID='postID', :border="true")
              .w-2
              select-directory(v-if='$root.$data.state.common.isLogin' :postID='postID' :border="true")
              .w-2(v-if='$root.$data.state.common.isLogin')
              .cursor-pointer.bg-white.rounded.flex.justify-center.items-center.w-9.h-9.border(v-if='this.post.userID!==$root.$data.state.common.userID' text='' @click='toChat')
                v-tooltip(top)
                  template(v-slot:activator="{ on, attrs }")
                    img.w-5.h-5(src='@/assets/home/message_icon.png',v-bind="attrs", v-on="on")
                  span 聊天
              .w-2(v-if='this.post.userID!==$root.$data.state.common.userID')
              report(v-if='$root.$data.state.common.isLogin' :postID='postID' :border="true")
          v-row
            v-col
              h1.text-3xl.font-bold {{ post.title }}
              pre.mt-4 {{ post.content }}
          .link.flex.items-center.mt-5
            .bg-white.rounded.flex.justify-center.items-center.w-9.h-9.border
              img(src="@/assets/home/link_icon.png" class="w-5 h-5")
            .w-2
            a(target='_blank' @click="openTab") {{ post.url }}
  v-row
    v-col.py-2(cols='12')
      .flex.flex-col.items-center.py-9.relative
        .font-Serif.font-bold.text-xl.bottom-2 更多推薦您
        .font-Serif.text-sm Recommend
        .absolute.left-0.top-0.px-8.py-9.w-full
          .home_decoration_l.h-28
        .absolute.left-0.top-0.px-8.py-9.w-full
          .home_decoration_r.h-28
  v-row.px-8
    v-col.py-3(cols='12').flex.justify-center.items-center
      masonry-custom(:posts='posts' @imgClick='imgClick' currentPath='')

</template>
<script>
import MasonryCustom from '../Common/Masonry/MasonryCustom.vue'
import SelectDirectory from '../Common/Common/SelectDirectory.vue'
import SharePost from '../Common/Common/SharePost.vue'
import Report from './Post/Report.vue'
import ZoomImage from './Post/ZoomImage.vue'
import postAPI from '../../apis/post'
import userAPI from '../../apis/user'

import serverIP from '../../apis/common'

export default {
  components: { MasonryCustom, SelectDirectory, SharePost, Report, ZoomImage },
  name: 'Post',
  data () {
    return {
      currentImage: '',
      post: {
        userID: '',
        pictureList: []
      },
      posts: [],
      frontEndServer: null,
      postUserProfile: {
        photoURL: '',
        firstName: '',
        lastName: ''
      },
      postInputDS: {
        postID: null,
        from: 0,
        size: 6
      },
      numberOfPage: 0,
      postID: null,
      debouncefunc: null
    }
  },
  mounted () {
    this.postID = this.$route.params.id
    this.postInputDS.postID = this.postID

    window.scrollTo({ top: 0 })
    this.debouncefunc = this.debounce(this.handleScroll)
    window.addEventListener('scroll', this.debouncefunc)
    this.readPost(this.postID)
    this.getMorePost()
    this.frontEndServer = serverIP.frontEndServer
    console.log(this.post.url)
  },
  beforeDestroy () {
    window.removeEventListener('scroll', this.debouncefunc)
  },
  methods: {
    handleScroll () {
      const scrollHeight = window.scrollY
      const maxHeight =
        window.document.body.scrollHeight -
        window.document.documentElement.clientHeight - 30
      if (scrollHeight >= maxHeight * 1) {
        if (this.numberOfPage !== 0) {
          this.postInputDS.from +=
            this.postInputDS.from + this.postInputDS.size
          this.getMorePost()
        } else {
          this.getRandomPost()
        }
      }
    },
    debounce (func, delay = 250) {
      let timer = null
      return () => {
        const context = this
        const args = arguments

        clearTimeout(timer)
        timer = setTimeout(() => {
          func.apply(context, args)
        }, delay)
      }
    },
    openTab () {
      if (window.confirm(`即將開啟下列連結: ${this.post.url}，提醒您，不明連結可能包含惡意程式`)) {
        window.open(this.post.url, '_blank')
      }
    },
    imageClick (id) {
      this.currentImage = this.post.pictureList[id].pictureURL
    },
    toChat () {
      this.settakePostInfoToChat()
      this.settakeDataToChat()
      this.$router.push('/chat')
    },
    authorClick (nickName) {
      this.$router.push(`/profile/${nickName}`)
    },
    imgClick (id) {
      this.$router.push(`/post/${id}`)
    },
    settakePostInfoToChat () {
      this.$root.$data.settakePostInfoToChat(`${this.frontEndServer}/post/${this.postID}`)
    },
    settakeDataToChat () {
      this.$root.$data.settakeDataToChat({
        receiver: this.post.userID,
        newMessageCount: 0,
        time: new Date()
      })
    },

    readPost (postID) {
      if (this.$root.$data.state.common.isLogin === false) {
        postAPI.readPost(postID).then((response) => {
          this.post = response.data
          this.currentImage = this.post.pictureList[0].pictureURL
          return userAPI.getProfile(this.post.userID)
        }).then((response) => { this.postUserProfile = response.data })
      } else {
        postAPI.readPostForUser(postID).then((response) => {
          this.post = response.data
          this.currentImage = this.post.pictureList[0].pictureURL
          return userAPI.getProfile(this.post.userID)
        }).then((response) => { this.postUserProfile = response.data })
      }
    },
    getMorePost () {
      postAPI.morePost(this.postInputDS).then((response) => {
        this.numberOfPage = response.data.length
        if (this.numberOfPage !== 0) {
          response.data.map((post) => {
            this.posts.push({
              id: post.id,
              pictureURL: post.pictureList[0].pictureURL
            })
          })
        } else {
          this.getRandomPost()
        }
      })
    },
    getRandomPost () {
      postAPI.randomPost(6).then((response) => {
        response.data.map((post) => {
          this.posts.push({
            id: post.id,
            pictureURL: post.pictureList[0].pictureURL
          })
        })
      })
    }
  }
}
</script>

<style scoped>

.img-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-column-gap: 10px;
}

.drift-zoom-pane img{
  object-fit: contain !important;
}
.search-bg{
  left: 0;
  top: 0;
  overflow: hidden;
  background-image: url('~@/assets/background/profile_bg.png');
  background-position: top;
  background-size: cover;
}
</style>
