<template lang="pug">
.login.flex.w-screen.h-full.paper-bg
  .register-bg.h-full.hidden(class="md:block")
  .login-page.h-full.flex.justify-center.items-start.flex-col
    .flex.p-2.flex-col.justify-center(class="md:px-56")
      .flex
        .font-bold.text-3xl 建立帳號
        .flex-1
        img.w-10.h-10(src="@/assets/logo_no_txt.png")
      .text-red-500.cursor-pointer.mb-10(@click="$emit('nextPage')") 已有帳號嗎？立即登入
      .text-base 使用社交帳戶登入
      .flex.mt-4.max-h-min(style="flex: 0 !important")
        a.w-10.h-10.mr-2(:href=`fbLoginURL`)
          img.w-10.h-10(src="@/assets/login/Facebook.png")
        a.w-10.h-10(:href=`googleLoginURL`)
          img.w-10.h-10(src="@/assets/login/Google.png")
      .i
        img.login-or.my-7.w-full(src="@/assets/login/or.png")
      .text-base.font-medium.mb-5 使用電子郵件註冊
      .flex.mb-3.items-start.flex-col.justify-start(class="md:flex-row")
        input.login-input.rounded-sm.bg-white.px-3.py-2.w-full(placeholder="請輸入電子郵件" v-model="registerDTO.userDS.username")
        .btn-pri.rounded.confirm-code-btn.cursor-pointer.px-2(@click="registrationVerification" style="min-width: max-content;") 傳送驗證碼
      input.login-input.rounded-sm.bg-white.px-3.py-2.mb-3(placeholder="請輸入驗證碼" v-model="registerDTO.checkCode")
      input.login-input.rounded-sm.bg-white.px-3.py-2.mb-3(placeholder="請輸入密碼" v-model="registerDTO.userDS.password" type="password")
      input.login-input.rounded-sm.bg-white.px-3.py-2.mb-3(placeholder="請再次輸入密碼" v-model="registerDTO.secondPassword" type="password")
      .flex.max-h-min.items-center
        input.w-5#r-checkbox(type="checkbox")
        a(for="r-checkbox", href="/privacy" target="_blank").text-sm 我已閱讀並接受會員條款。
      .login-btn.mt-10.bg-gray-600.text-white.px-4.py-3.rounded.max-w-max.cursor-pointer(@click="localRegister") 建立帳號
</template>
<script>
import serverIP from '@/apis/common'
import userAPI from '@/apis/user'

export default {
  name: 'Register',
  data () {
    return {
      passwordVisible: false,
      registerDTO: {
        secondPassword: '',
        checkCode: '',
        userDS: {
          username: '',
          password: ''
        }
      },
      frontEndServer: '',
      fbLoginURL: '',
      googleLoginURL: ''
    }
  },
  mounted () {
    this.frontEndServer = serverIP.frontEndServer
    this.fbLoginURL = `https://www.facebook.com/v15.0/dialog/oauth?client_id=4123752121082557&redirect_uri=${this.frontEndServer}/third_party_login/fb&state={state-param}&response_type=code&scope=public_profile email`
    this.googleLoginURL = `https://accounts.google.com/o/oauth2/v2/auth?client_id=137072900737-h8ud0r0ubjed2tb0vv1j3s7fk5gn1mo4.apps.googleusercontent.com&redirect_uri=${this.frontEndServer}/third_party_login/google&response_type=code&scope=profile email`
  },
  methods: {
    registrationVerification () {
      userAPI.registrationVerification(this.registerDTO).then((response) => {
        if (response.data === true) {
          alert('驗證碼已寄到您的信箱，請查看您的信箱')
        } else {
          alert('傳送失敗，可能原因：\n1.電子郵件格式錯誤\n2.同一電子郵件30分鐘內，只能傳送一次驗證碼')
        }
      })
    },
    localRegister () {
      userAPI.localRegister(this.registerDTO).then((response) => {
        if (response.data === true) {
          alert('註冊成功')
          this.$emit('nextPage')
        } else {
          alert('註冊失敗，可能原因：\n1.電子郵件已註冊\n2.密碼輸入二次不同\n3.密碼需要包含大小寫英文和數字')
        }
      })
    }
  }
}
</script>

<style>
.register-bg{
  background-image: url('~@/assets/login/register_bg.png');
  background-position: center;
  background-size: cover;
  flex:4;
}
.paper-bg{
  background-image: url('~@/assets/background/paper_bg.png');
  background-position: center;
  background-size: cover;
}
.confirm-code-btn{
  background-color: #C2321F;
  color:white;
  width:90px;
  line-height: 40px;
  vertical-align:middle;
  text-align: center;
  border-radius: 0px 2px 2px 0px;
}
</style>
