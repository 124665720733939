<template>
  <v-app>
    <v-main>
      <v-container fluid>
        <v-row class="fill-height">
          <v-col>
            <div><v-btn class="mr-2" @click="toStep(1)">使用者列表</v-btn><v-btn @click="toStep(2)">產品列表</v-btn><v-btn @click="toStep(3)">檢舉列表</v-btn></div>
            <br>
            <v-card class="elevation-12">
              <v-window v-model="step">
                <v-window-item :value="1">
                  <user-list></user-list>
                </v-window-item>
                <v-window-item :value="2">
                  <product-list></product-list>
                </v-window-item>
                <v-window-item :value="3">
                  <report-list></report-list>
                </v-window-item>
              </v-window>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
    <Footer></Footer>
  </v-app>
</template>
<script>
import ProductList from './Admin/ProductList.vue'
import ReportList from './Admin/ReportList.vue'
import UserList from './Admin/UserList.vue'
import Footer from '../Common/Layout/Footer.vue'

export default {
  components: { Footer, UserList, ProductList, ReportList },
  name: 'Admin',
  data () {
    return {
      step: 1
    }
  },
  mounted () {},
  methods: {
    toStep (step) {
      this.step = step
    }
  }
}
</script>
