<template lang="pug">
.min-h-full.w-screen.flex.flex-col.justify-center.items-center
  .fixed.thridPayment-bg.w-full.h-full
  v-card.rounded-2xl.bg-white.flex.flex-col.justify-center.items-center.p-8(class="w-10/12", style="max-width:600px")
    div(v-if='this.pricePlan !==-1') 付款處理中...
    div(v-else='this.pricePlan !==-1') 您的付款未完成，請返回首頁
    .h-9
    .cursor-pointer.bg-pri.p-2.rounded(v-if='this.pricePlan ===-1' @click='toHome') 返回首頁
  form#allPayAPIForm(action='https://core.newebpay.com/MPG/mpg_gateway' method='post')
    input(type='hidden' name='MerchantID' :value='paymentInfo.MerchantID')
    input(type='hidden' name='Version' :value='paymentInfo.Version')
    input(type='hidden' name='TradeInfo' :value='paymentInfo.TradeInfo')
    input(type='hidden' name='TradeSha' :value='paymentInfo.TradeSha')

</template>
<script>
import payAPI from '@/apis/pay'

export default {
  name: 'ThirdPaymentNewebPay',
  data () {
    return {
      paymentInfo: '',
      pricePlan: null
    }
  },
  async mounted () {
    this.pricePlan = this.$root.$data.state.pay.pricePlan
    if (this.pricePlan !== -1) {
      await this.createOrder()
    }
  },
  methods: {
    toHome () {
      this.$router.push('/home')
    },

    createOrder () {
      return payAPI.createOrder(this.pricePlan).then((response) => {
        if (Object.keys(response.data).length === 0) {
          alert('購買失敗，可能原因：\n1.您已購買過首購方案\n2.您還是會員\n3.您目前的收藏品數量大於要購買的收藏品數量')
          this.$router.push('/paid_plan')
        } else {
          this.paymentInfo = response.data
          this.$nextTick(() => { document.getElementById('allPayAPIForm').submit() })
        }
      })
    }
  }
}
</script>

<style scoped>
.thridPayment-bg{
  background-image: url('~@/assets/background/profile_bg.png');
  background-position: top;
  background-size: cover;
}
</style>
