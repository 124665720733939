import requestHelper from './helper'
import axios from 'axios'
import serverIP from './common'

const postURL = '/post'

const postAPI = {
  createPost: post => (requestHelper.post(postURL, post)),
  readPost: postID => (axios.get(`${serverIP.backEndServer}${postURL}/${postID}`)),
  readPostForUser: postID => (requestHelper.get(`${postURL}/readPostForUser/${postID}`)),
  randomPost: generatePostNumber => (axios.get(`${serverIP.backEndServer}${postURL}/randomPost/${generatePostNumber}`)),
  searchPost: postInputDS => (axios.get(`${serverIP.backEndServer}${postURL}/searchPost?searchText=${postInputDS.searchText}&from=${postInputDS.from}&size=${postInputDS.size}`)),
  morePost: postInputDS => (axios.get(`${serverIP.backEndServer}${postURL}/morePost/${postInputDS.postID}?from=${postInputDS.from}&size=${postInputDS.size}`)),
  revisePost: postDS => (requestHelper.post(`${postURL}/revisePost`, postDS)),
  countUserPost: postID => (axios.get(`${serverIP.backEndServer}${postURL}/countUserPost/${postID}`)),
  createReportForPost: reportDS => (requestHelper.post(`${postURL}/createReportForPost`, reportDS)),
  readReportForPost: () => (requestHelper.get(`${postURL}/readReportForPost`)),
  reviseAuditResultsForPostReport: reportDS => (requestHelper.post(`${postURL}/reviseAuditResultsForPostReport`, reportDS))
}

export default postAPI
