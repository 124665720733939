<template lang="pug">
.privacy-bg.w-screen.h-full
  .p-10
    h1.h1 會員條款
    p 歡迎各位收藏者來到藏家會，您可以探索和分享來自各個時期和地區的古董藝術品。讓我們一齊創造一個廣泛的藏品分享平台，不限於古代雕塑、古董家具、繪畫、陶瓷、珠寶和文物等領域。
    ul.ul
      li 請尊重每個收藏者與收藏品。請記得我們都是來這裡分享及欣賞藏品的。
      li 請給予建設性的批判、留⾔和回饋。如果有⼈需要幫助，請您將⼼⽐⼼對待對⽅。盡量保持具體、明確，⽽且要有建設性。
      li 請深思您使⽤藏家會提供的⼯具和服務的⽅式。如果不當使⽤我們的平台，可能會導致您的會員被終⽌。
    h2.h2 一、 會員條款之接受與修改
    ol.ol
      li 會員條款是藏家會會員(下稱「會員」或「您」)與大邁藝術有限公司(下稱「藏家會」或「本公司」)之間，就訂閱藏家會付費方案(下稱「本服務」或「付費方案」)所生相關權利義務之完整契約。
      li 會員須先審閱並同意藏家會會員條款(以下稱<a href="https://collectorscollection.com.tw/privacy">「會員條款」</a>)並提供真實且正確之必要個人資料完成註冊後，始可訂閱本服務並依所選擇之特定付費方案使用。
      li 倘會員為未成年人(包括限制行為能力人者或無行為能力人者)，應由法定代理人或監護人閱讀並同意會員條款，始生效力。倘會員之法定代理人或監護人不同意會員條款之全部或部分或不知情者，除有民法第 83 條之情形者外，會員條款對該會員並不生效力。
      li 您瞭解並同意一旦訂閱本服務，即視為您已認知並同意會員條款內容。
      li 為提供會員更好之付費方案或基於本公司營運所需，本公司得隨時變更會員條款內容。倘會員條款有修改或變更者，您同意本公司在做出上述改變或修改前，將在藏家會相關頁面上事先公告修改的內容與生效日。若您不同意修改或變更者，得於生效日前通知本公司後即刻停止使用本服務，惟如您於生效日後仍繼續使用本服務者，則視為您已同意會員條款之修改或變更，本公司將依照修改或變更後之會員條款內容對您繼續提供本服務。
    h2.h2 二、 付費方案說明
    ol.ol
      li 您得享有上傳收藏品及編輯藏品簡介及個人檔案等資訊服務。
      li 您瞭解並同意其於訂閱前，已透過相關頁面中之說明，瞭解各方案下所提供之功能、可上傳藏品數量及費用。謹請注意，藏家會 付費方案所享權益有所不同，且每一付費方案所得上傳藏品數量及享有權益亦可能有所不同，請您根據自身需求選擇訂閱之。
      li 您理解並同意，在您付費訂閱本服務後方可使用您指定的付費方案內容。前述需要訂閱方能使用上傳藏品等功能，您理解並同意，您所訂閱付費方案中之部分或全部功能，本公司有權根據自身運營策略之需要，免費提供予指定會員使用。
      li 您可利用下列方式訂閱本服務。
        ol.ol
          li 信用卡付款訂閱:
            p 信用卡付款訂閱付費方案，付款方案有月付及年付可選擇，付款訂閱本服務後，以首次訂購日作為下一週期相當日期之續訂日(例如，2022 年 5 月 23 日訂閱本服務月付方案，則 2022 年 6 月 22 日為月付方案結束日，故 6 月 23 日前需完成續訂否則即會關閉本服務，以此類推)。取消訂閱時，可使用本服務之期間與費用將計算至當次計費週期屆滿為止。
          li 序號代碼兌換訂閱:
            p 序號代碼為 藏家會 發行用於兌換訂閱本服務一定期間之一連串英文數字組合，各個序號代碼均不相同且僅可兌換一次，會員於取得序號代碼後，即可至兌換序號代碼專頁中輸入該序號代碼後完成訂閱本服務，一旦支援序號期限查詢，則可使用之本服務期間應以序號代碼兌換完成後所示者為準。部分序號代碼可能設有完成兌換之期限或單一會員帳號兌換數量之限制，其以取得該序號代碼時所附說明為準。
      li 本服務屬於消費者保護法所規範之「通訊交易解除權合理例外情事適用準則」第 2 條第 5 款「一經提供即為完成之線上服務」，不適用消費者保護法第 19 條猶豫期間解除權之規定。
      li 倘本服務之全部或部分之價格有調整者，本公司將於價格調整生效前一個月公告，如您不願接受調整後之價格，請勿於調整生效日後使用本服務。
      li 本公司授與會員於線上使用本服務的權利，會員僅得依照會員條款使用本服務。會員不得對本服務進行還原工程、反向組譯、解編或違反規避技術之限制，會員亦不得停用、竄改或以其他方式試圖規避針對會員線上服務使用情況而制定的任何機制。
      li 會員不得將付費方案之使用權利之全部或部分以授權、出租、出借、轉售、移轉、共同使用或以主機服務形式提供予第三方為有償或無償之使用
      li 會員同意並保證不得利用本服務從事侵害他人權益或違法之行為，包括但不限於：
        ul.ul
          li (1)上載、張貼、公布或傳送任何誹謗、侮辱、具威脅性、攻擊性、不雅、猥褻、不實、違反公共秩序或善良風俗或其他不法之文字、圖片或任何形式的檔案於本服務上；
          li (2)侵害他人名譽、隱私權、營業秘密、商標權、著作權、專利權、其他智慧財產權及其他權利；
          li (3)違反依法律或契約所應負之保密義務；
          li (4)冒用他人名義使用本服務；
          li (5)上載、張貼、傳輸或散佈任何含有電腦病毒或任何對電腦軟、硬體產生中斷、破壞或限制功能之程式碼之資料；
          li (6)從事不法交易行為或張貼虛假不實、引誘或教唆他人犯罪之訊息；
          li (7)販賣槍枝、毒品、禁藥、盜版軟體或其他違禁物；
          li (8)提供賭博資訊或以任何方式引誘或教唆他人參與賭博；
          li (9)濫發廣告訊息、垃圾訊息、連鎖信、違法之多層次傳銷訊息等；
          li (10)以任何方法傷害未成年人；
          li (11)偽造訊息來源或以任何方式干擾傳輸來源之認定；
          li (12)干擾或中斷本服務或伺服器或連結本服務之網路，或不遵守連結至本服務之相關需求、程序、政策或規則等，包括但不限於使用任何設備、軟體或刻意規避本服務之排除自動搜尋之標頭；
          li (13)對於恐怖行動提供任何實質或直接、間接之支持或資源；
          li (14)追蹤他人或其他干擾他人或為前述目的蒐集或儲存他人之個人資訊；
          li (15)其他本公司有正當理由認為不適當之行為。
      li 若會員違反會員條款之義務及承諾，本公司有權暫停或終止使用者帳號，並拒絕會員使用全部或部分服務，會員不得異議。
      li 會員同意並保證本服務之使用不會侵害本公司或他人權益或違法之行為。
      li 任何資料一經會員上載、傳送、輸入 或提供予本服務時，即表示會員同意：
        ul
          li (1)由本公司及關係企業儲存或管理該資料，並由本公司及關係企業之搜尋工具進行索引及抓取，並公開刊登、使用於本公司及關係企業的相關系統網路上，包括但不限於本公司所合作的第三人網站上；
          li (2)授權本公司及關係企業可以基於公益或為宣傳、推廣或經營本平台及本服務之目的，進行使用、修改、重製、公開播送、改作、散布、發行、公開發表、公開傳輸、公開上映、翻譯該等資料，並得在此範圍內將前述權利轉授權他人。
          li (3)會員並保證本公司及關係企業使用、修改、重製、公開播送、改作、散布、發行、公開發表、公開傳輸、公開上映、翻譯、轉授權該等資料，不致侵害任何第三人之智慧財產權或其他權利。

    h2.h2 三、 責任限制
    ol.ol
      li 您瞭解本服務係按您訂閱及使用當時之「現況」提供。
      li 本公司依據本服務所產生對會員之所有索賠限於直接損害，所有請求賠償金額之上限，為發生訴訟情事之前該會員12個月期間依本服務所支付之金額。
      li 雙方對他方因收入損失或間接、特殊性、附隨性、衍生性、懲罰性或懲戒性損害，或是因利潤損失、收入、營業中斷或商業資訊遺失所導致的損害，均不負任何賠償責任，即使該方已獲知該等損害之可能性或可合理預見，亦不影響。
    h2.h2 四、 智慧財權產及瑕疵擔保
    ol.ol
      li 本服務之所有內容，包括但不限於文字、軟體、聲音、圖片、影片、圖表、網站架構、網站畫面的安排、網頁設計均由本公司或其他權利人依法擁有其智慧財產權，包括但不限於著作權、商標權、專利權等。非經本公司或其他權利人書面同意，不得擅自使用、修改、複製、傳播、變更、散佈、發行或發表相關內容。如有違反，會員同意承擔因此給本公司和其他權利人所造成的一切損失。
      li 除會員條款明示之擔保外，本公司不提供一切明示、默示、法定或其他之擔保，包括適售性或符合特定目的之擔保。
      li 如果第三方指控本平台軟體侵害第三方之專利權、著作權或商標權或非法使用其營業秘密，而對會員提出法律主張時，本公司將會協助會員提出抗辯或說明。 但若因會員使用本平台軟體時侵害第三方之專利權、著作權或商標權，或係故意非法使用其營業秘密，致使本公司亦遭第三方提出法律主張時，會員亦將須協助本公司提出抗辯或說明。
    h2.h2 五、 期限及終止
    ol.ol
      li 會員條款於會員之訂購屆滿、終止或未經續約而失效，以較早發生之日期為準。
      li 會員得於期間內隨時終止訂購，但不論是會員終止訂購或本公司依會員條款終止，會員預先支付的本服務使用費用，本公司將不會退回。
      li 若發生下列情況，本公司得終止會員線上平台使用本服務之權利：
        p (1)會員違反會員條款情節重大者；
        p (2)會員未依本服務支付應付金額。本公司在終止之前會訂合理的期間告知會員，如會員並未在此期間內充分說明並解決導致終止的原因，本公司將立即終止會員之使用者帳號，並得向會員請求因此造成的損害，包括但不限於第三人向本公司請求的損害賠償，以及因任何訴訟產生的訴訟費用及合理律師費用。
      li 本公司得因經營政策或其他考量終止本服務之提供，但應於終止前 2 個月以適當方式公告予會員知悉。
    h2.h2 六、 其他約定
    ol.ol
      li 倘會員條款有任何部份被宣告為不適法、無效或基於任何理由不可執行，則該部份並不影響其餘部份之有效性及可執行性。
      li 本公司對會員違反會員條款之情事(如有)如未採取任何處置或法律行動，並不表示本公司放棄該處置或法律行動之權利，亦不影響本公司將來就類似情事採取處置或法律行動的權利。
      li 會員條款之解釋與適用，以中華民國法令為準據法。如因本服務或會員條款產生或相關之訴訟，雙方同意以臺灣臺中地方法院為第一審管轄法院。
    h2.h2 有關本會員條款及訂購的重要說明及解釋
    p 訂購期間(1個月/1年)：訂購期間的計算以日曆日為準，亦即自訂購生效日至次月/年的前一日止。例如於5月5日訂購1個月，則使用期間為5月5日至6月4日止。
    h3.h3 取消訂購期間
    p 如取消訂購，則取消訂購日自次月份起算。例如於5月5日訂購1個月後，於5月19日取消訂購，則取消訂購生效日為6月5日。
    h3.h3 年付方案優惠的取消
    p 原訂購年度使用權，如於使用期間取消，則費用的計算改採月計算，經重新計算後，如有溢付，本公司將會退還會員，如有不足，會員應補足差額。
    p 例如月付方案為720元，年付方案為7,200元，會員自5月5日訂購年付方案約一年後，於隔年4月1日取消訂購，則取消訂購日為4月4日，訂購期間共計11個月，依照月付方案計算的使用費用為7,920元，則會員應補720元給本公司。
    p 例如月付方案為720元，年付方案為7,200元，會員自5月5日訂購年付方案一年後，於9月1日取消訂購，則取消訂購日為9月4日，訂購期間共計4個月，依照月付方案計算的使用費用為2,880元，則本公司將退還4,320元予會員。
//- 原內容暫不顯示
    p 本《會員條款》(簡稱「條款」)規範您如何存取和使用藏家會網站。請詳閱本條款，如有任何問題，請與我們聯絡。使用藏家會，即表示您同意遵守本條款。
    p 1.會員了解並同意上傳於藏家會之內容需與收藏品有關。
    p 2.會員了解並同意藏家會上傳收藏品之相關訊息或圖片(以下稱「藏品」)，均由會員上傳、刊載或提供，藏家會僅作有關之資訊處理服務，故不保證所有訊息、圖片等資料之完整、及時、真實或合法性，亦不保證交易雙方當事人間之履約及償債能力。雙方因買賣後後所發生之任何爭議、糾紛或損失，及造成他人個人資料權利損害等，概由會員自行依循正當法律途徑解決，與藏家會無涉。
    p 3.會員了解並同意其與藏家會間之會員付費服務之法律關係非為消費者保護法所稱之「特種買賣」，故無消費者保護法第十九條第一項規定「七天鑑賞期」之適用。
    p 4.會員了解並同意，藏家會為提供穩定的系統服務，將進行不定期例行性之系統維護，於該維護期間，將暫停或中斷本網站服務之全部或部份，此為網站維護之必然程序，藏家會除提前公告會員外，將不再另行補償或延長合約期限。
    p 5.會員註冊後所取得之帳號密碼，需妥善保管，會員保證不將藏家會會員專屬之帳號密碼，提供予關係企業或他人使用。會員如發現帳號或密碼遭人非法或不當使用，請立即通知藏家會客服人員。
    p 6.會員同意藏家會得於合理之範圍內蒐集、處理、保存、傳遞及使用本條款第(2)條之資料，並同意藏家會將藏品收錄於搜尋引擎增加會員曝光機會，以提供使用者其他資訊或服務、或作成會員統計資料、或進行關於網路行為之調查或研究，或為任何之合法使用。
    p 7.會員了解並同意，使用藏家會提供之服務，並不代表雙方間已成立代理、合夥、委任、合作經營之法律關係。
    p 8.藏家會有權視會員上傳之藏品性質(非個人所有、非收藏品)、或上傳之內容是否有違反法令、公序良俗之情事、或遭其他會員申訴紀錄，保留或調整全部或一部份藏品之權利。
    p 9.會員付款購買藏家會會員之服務前，需詳閱本條款，購買後即表示了解並接受藏家會所提供之服務內容以及權利義務，合約期間內，會員不得提前終止本契約，若違反本項約定，該會員同意支付新台幣500元予藏家會做為違約金補償，並另外扣除服務費後退還，其服務費依購買方案使用比例計算。
    p 10.會員了解並同意贈送之加值、付費服務及補償內容，不得轉讓、退款、兌換現金...等用途。
    p 11.藏家會保留隨時調整本會員服務條款內容、服務或收費之權利，會員茲同意接受並適用調整後之內容、服務或收費。
    p 12.會員同意如有違反上述條款，或違反網站規定，如：上傳內容與收藏無關，藏家會有權立即終止服務，會員已繳付之費用則作為懲罰性違約金，無須退還之。
    p 13.因會員條款所生之爭議有涉訟之必要時，雙方同意以臺灣臺中地方法院為第一審管轄法院。
    p 14.本服務已安裝 Google Analytics (分析) 廣告功能。本功能僅用於提供「藏家會」分析產品廣告投放效益及優化使用流程，如您欲停用 Google Analytics (分析) 廣告功能，建議您可参考Google所提供<a href="https://tools.google.com/dlpage/gaoptout" target="_blank">不透露資訊的方式</a>以停用該功能。

</template>
<script>
export default {
  name: 'About'
}
</script>

<style>
.privacy-bg{
  background-image: url('~@/assets/background/profile_bg.png');
  background-position: top;
  background-size: cover;
}
.h1{
  font-size: 2em;
}
.h2{
  font-size: 1.5em;
}
.h3{
  font-size: 1.2em;
}
.h1, .h2, .h3, li{
  margin-bottom: 0.5em;
}
.ul{
  list-style: initial;
}
.ol{
  list-style-type: decimal;
}
.ul, .ol{
  margin-bottom: 1em;
}
.ol .ol{
  list-style-type: upper-alpha;
}
.ol .ul{
  list-style: none;
}
</style>
