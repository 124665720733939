<template>
  <div>
    <div>即將開啟下列連結:</div>
    <div>{{url}}</div>
    <div>提醒您，不明連結可能包含惡意程式</div>
    <v-btn @click="redirect">確定</v-btn>
  </div>
</template>
<script>
export default {
  name: 'RedirectToExternalLink',
  data () {
    return {
      url: ''
    }
  },
  mounted () {
    this.url = this.$route.query.url
  },
  methods: {
    redirect () {
      location.href = this.url
    }
  }
}
</script>
