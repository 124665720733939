<template lang="pug">
.paid-plan-bg.w-screen.flex.justify-top.items-center.flex-col
  .wrapper.h-full.flex.flex-col.justify-center.items-center.p-4.paid-plan-layout.mt-10
    .text-4xl.mb-10 方案與定價
    .text-3xl.mb-4.plan-title 首購方案
    .bg-white.shadow.rounded-sm.p-6.relative.w-full.text-center.mb-10
      .text-lg.mb-10.text-justify 新註冊會員可選擇此方案，99元即享有一個月藝術愛好者會員資格，趕快上傳分享您的藝術收藏吧！
      .btn.min-w-max.bg-pri &nbsp99/月&nbsp
    .text-3xl.mb-4.plan-title 長期方案
    .flex.flex-row.w-full.gap-4
      .month.bg-white.shadow.rounded-sm.p-6.relative.w-full.text-center.flex.flex-col.flex-1.justify-between(v-for="(item, index) in planArray")
        .directions-group
          .text-2xl.mb-2 {{item.title}}
          .text-xl.font-medium 藏品數：{{ item.numberOfPieces }}件
          hr.mb-4.mt-4
          .text-lg.text-justify.mb-4 {{ item.directions }}
        .btn-group
          .btn.text-sm.mb-1 月方案 {{ item.monthPlanPrice }}/月
          .btn.text-sm 年方案 {{ item.yearPlanPrice }}/月

    //- .month.bg-white.shadow.rounded-sm.p-6.mt-8.relative.w-full
    //-   .text-sm Step1
    //-   .sub-title 選擇購買天數
    //-   v-slider.mt-4(color="#C2321F" track-color="#E4E7ED"  v-model='duringSelect' :max="(numberOfDuringArray.length-1 < 0 ? 0:numberOfDuringArray.length-1)", :tick-labels="numberOfDuringArray" @change="postCountSelect = 0;productsSelect=null")
    //-   .absolute.right-0.top-0.text-right.p-6
    //-     .text-xs 拖曳滑塊選擇所需天數
    //- .month.bg-white.shadow.rounded-sm.p-6.mt-5.relative.w-full
    //-   .text-sm Step2
    //-   .sub-title 選擇可上傳收藏品件數
    //-   .flex.flex-wrap.mt-8.flex-col(class="md:flex-row")
    //-     .select-item.rounded.shadow.px-2.py-1.mr-4.cursor-pointer.mb-2(v-for="(postCount,index) in numberOfPostCountArray[duringSelect]" :class="{'red-border' : postCountSelect ==index}" @click="()=>{postCountSelect = index;productsSelect=null}")
    //-       .item.font-bold {{postCount}} 張
    //- .month.bg-white.shadow.rounded-sm.p-6.mt-5.relative.w-full
    //-   .text-sm Step3
    //-   .sub-title 選擇方案
    //-   .flex.flex-wrap.mt-8.flex-col(class="md:flex-row")
    //-     .select-item.rounded.shadow.px-2.py-1.mr-4.cursor-pointer.mb-2.w-max(v-for="product in productArray[duringSelect][postCountSelect][0]" @click="productsSelect = product", :class="{'red-border' :  productsSelect &&  productsSelect.id == product.id}")
    //-       .item.font-bold {{product.item}}
    //- .month.rounded-sm.mt-5.relative.w-full.flex.items-center.justify-between
    //-   .flex.items-center.justify-between.bg-white.shadow.p-2.mr-2(style="flex:1")
    //-     .text-base 總金額
    //-     .text-lg.text-pri.font-bold.mr-2(v-if="productsSelect") NT {{productsSelect && productsSelect.price}}
    //-   .flex.justify-end.h-full
    //-     .bg-pri.rounded.shadow.px-2.py-1.cursor-pointer.self-end.h-full.flex.justify-center.items-center(:class="{'bg-disable': this.productsSelect ? false: true}", style="width: fit-content")
    //-       .text(@click="toThirdPayment(productsSelect.id)") 立即購買

</template>
<script>
import payAPI from '../../apis/pay'

export default {
  name: 'PaidPlan',
  data () {
    return {
      numberOfDuringArray: [],
      duringSelect: 0,
      numberOfPostCountArray: [],
      postCountSelect: 0,
      productArray: [[[]]],
      productsSelect: null,
      planArray: [
        {
          title: '藝術愛好者',
          numberOfPieces: 20,
          directions: '開啟您的藝術之旅。深入了解精彩的藝術世界，欣賞各種風格的作品，與同樣熱愛藝術的人們分享交流',
          monthPlanPrice: 299,
          yearPlanPrice: 239
        },
        {
          title: '資深收藏家',
          numberOfPieces: 50,
          directions: '升級您的收藏體驗。擴大您的收藏件數，深入探索獨特的藝術品，與專家互動，更深入地理解藝術背後的意義',
          monthPlanPrice: 499,
          yearPlanPrice: 339
        },
        {
          title: '專業收藏家',
          numberOfPieces: 100,
          directions: '達到藝術收藏的巔峰。建立令人矚目的收藏，獲得專屬活動及邀請，和藝術界頂尖人士聯繫，展示您的專業與影響力',
          monthPlanPrice: 699,
          yearPlanPrice: 559
        }
      ]
    }
  },
  computed: {
  },
  mounted () {
    this.readProduct()
  },
  methods: {
    toThirdPayment (pricePlan) {
      if (!this.productsSelect) {
        return
      }
      this.$root.$data.setpricePlan(pricePlan)
      this.$router.push('/third_payment')
    },

    readProduct () {
      payAPI.readProduct().then((response) => {
        const data = response.data.filter(product => product.isVisible === true)
        const numberOfDuringSet = new Set()
        for (let i = 0; i < data.length; i++) {
          numberOfDuringSet.add(data[i].during)
        }
        const numberOfDuringArray = [...numberOfDuringSet]
        numberOfDuringArray.sort((a, b) => {
          return a - b
        })

        const numberOfPostCountArray = []
        for (let i = 0; i < numberOfDuringArray.length; i++) {
          const temp = data.filter(product => product.during === numberOfDuringArray[i])
          const tempOfPostCountSet = new Set()
          for (let i = 0; i < temp.length; i++) {
            tempOfPostCountSet.add(temp[i].postCount)
          }
          numberOfPostCountArray.push([...tempOfPostCountSet].sort((a, b) => {
            return a - b
          }))
        }

        const productArray = []
        for (let i = 0; i < numberOfPostCountArray.length; i++) {
          productArray.push([])
          for (let j = 0; j < numberOfPostCountArray[i].length; j++) {
            productArray[i].push([])
            const temp = data.filter(product => (product.during === numberOfDuringArray[i] && product.postCount === numberOfPostCountArray[i][j]))
            productArray[i][j].push(temp.sort((a, b) => {
              if (a.item < b.item) { return -1 }
              if (a.item > b.item) { return 1 }
              return 0
            }))
          }
        }

        this.numberOfDuringArray = numberOfDuringArray
        this.numberOfPostCountArray = numberOfPostCountArray
        this.productArray = productArray
      })
    }
  }
}
</script>

<style>

.paid-plan-bg{
  background-image: url('~@/assets/background/profile_bg_2.png');
  background-position: top;
  background-size: cover;
}

.red-border{
  border: 2px solid #C2321F;
}

.month{
  max-width: 556px;
}

.paid-plan-layout{
  width: 50%;
}

.btn.min-w-max.bg-pri{
  margin: 0 auto;
}

.btn-group>.btn.text-sm {
  width: 100%;
  border: 1px solid #eaeaea;
  color: #C2321F;
}

@media screen and (max-width: 768px) {
  .paid-plan-layout{
    width: 80%;
  }

  .flex.flex-row.w-full.gap-4{
    flex-direction: column !important;
  }

}

</style>
