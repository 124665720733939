<template lang="pug">
div
  .font-weight-bold {{ altedCurrentPath }}
</template>
<script>
export default {
  name: 'ProfileHeadInDirectory',
  props: ['currentPath', 'share'],
  methods: {
    deleteDirectory () {
      this.$$emit('deleteDirectory')
    }
  },
  computed: {
    altedCurrentPath () {
      if (this.currentPath.path.startsWith('home/track')) {
        return `最愛收藏${this.currentPath.path.split('home/track')[1]}`
      } else if (this.currentPath.path.startsWith('home/upload')) {
        return `收藏品${this.currentPath.path.split('home/upload')[1]}`
      }
      return this.currentPath.path
    }
  }
}
</script>
