import SockJS from 'sockjs-client'
import Stomp from 'stompjs'

import userAPI from '../apis/user'
import chatAPI from '../apis/chat'

import serverIP from '../apis/common'

let stompClient = null

const store = {
  debug: false,
  state: {
    app: {
      snackbarCustom: {
        isOpen: false,
        message: ''
      },
      newMessageCount: 0
    },
    common: {
      userID: -1,
      isLogin: false,
      profile: {
        photoURL: '',
        firstName: '',
        lastName: '',
        introduction: '',
        siteURL: ''
      }
    },
    post: {
      postsForHome: []
    },
    chat: {
      chatFlag: false,
      chatDTO: null,
      takeDataToChat: null,
      takePostInfoToChat: ''
    },
    pay: {
      pricePlan: -1
    }
  },
  setSnackbarCustomIsOpenAction (isOpen) {
    if (this.debug) console.log('setSnackbarCustomIsOpenAction triggered with', isOpen)
    this.state.app.snackbarCustom.isOpen = isOpen
  },
  setSnackbarCustomMessageAction (message) {
    if (this.debug) console.log('setSnackbarCustomMessageAction triggered with', message)
    this.state.app.snackbarCustom.message = message
  },
  clearNewMessageCountAction () {
    this.state.app.newMessageCount = 0
  },
  setpostsForHome (post) {
    if (this.debug) console.log('setpostsForHome triggered with', post)
    this.state.post.postsForHome.push({
      id: post.id,
      pictureURL: post.pictureList[0].pictureURL
    })
  },
  setchatFlag (chatFlag) {
    if (this.debug) console.log('setchatFlag triggered with', chatFlag)
    this.state.chat.chatFlag = chatFlag
  },
  settakeDataToChat (takeDataToChat) {
    if (this.debug) console.log('settakeDataToChat triggered with', takeDataToChat)
    this.state.chat.takeDataToChat = takeDataToChat
  },
  settakePostInfoToChat (takePostInfoToChat) {
    if (this.debug) console.log('settakePostInfoToChat triggered with', takePostInfoToChat)
    this.state.chat.takePostInfoToChat = takePostInfoToChat
  },
  setpricePlan (pricePlan) {
    if (this.debug) console.log('setpricePlan triggered with', pricePlan)
    this.state.pay.pricePlan = pricePlan
  },

  async init () {
    await this.getUserInfo()
    await this.getProfile(this.state.common.userID)
    this.getUserCountFromMessages()
    this.webSocketConnect()
  },
  getUserInfo () {
    return userAPI.getUserID().then((response) => {
      this.state.common.userID = response.data
      this.state.common.isLogin = true
    })
  },
  getProfile (userID) {
    return userAPI.getProfile(userID).then((response) => {
      this.state.common.profile = response.data
    })
  },
  getUserCountFromMessages () {
    chatAPI.getUserCountFromMessages().then((response) => {
      this.state.app.newMessageCount = response.data
    })
  },
  webSocketConnect () {
    const token = localStorage.getItem('user-token')
    const sock = new SockJS(`${serverIP.backEndServer}/webSocketApp?access_token=` + token)
    stompClient = Stomp.over(sock)
    stompClient.debug = () => {}
    stompClient.connect({}, (frame) => {
      stompClient.subscribe('/user/queue/chat', (message) => {
        this.getUserCountFromMessages()
        this.state.chat.chatFlag = true
        this.state.chat.chatDTO = JSON.parse(message.body)
      })
    })
  },
  webSocketDisConnect () {
    if (stompClient != null) {
      stompClient.disconnect()
    }
  },
  close () {
    localStorage.removeItem('user-token')
    localStorage.removeItem('user-token-timeout')
    this.state.app.newMessageCount = 0
    this.state.common.userID = -1
    this.state.common.isLogin = false
    this.state.common.profile = {}
    this.webSocketDisConnect()
  }

}

export default store
