<template lang="pug">
.chatUserListWrapper
  .w-full.p-2.cursor-pointer(v-for='(item, index) in chatMailBox' @click='clearchatMailBoxNewMessageCountAction(index)')
    .flex.flex-row.items-center.m-0.p-2.rounded(class="hover:bg-gray-100")
      .mr-5.flex
        img.rounded-full.object-cover.w-14.h-14(:src="item.receiverAvatar")
      .flex.flex-col.hidden(style="flex: 1" class="md:block")
        .flex.items-center.justify-between(style="flex: 1")
          .name.flex-1.mr-2.text-base.overflow-ellipsis {{item.receiverName}}
          .recieve.rounded-full.text-white.bg-pri.w-4.h-4.text-xs.text-center(v-if="item.newMessageCount != 0")
        .time.text-gray-400(style="font-size: 10px;")
</template>
<script>
import chatAPI from '../../../apis/chat'
import userAPI from '../../../apis/user'

export default {
  name: 'ChatUserList',
  props: ['chatFlag', 'chatDTO', 'toChatListTopCurrent'],
  async mounted () {
    let currentUser = null
    await this.findChatMailBox().then(() => {
      if (this.$root.$data.state.chat.takeDataToChat !== null) {
        for (let i = 0; i < this.chatMailBox.length; i++) {
          if (this.chatMailBox[i].receiver === this.$root.$data.state.chat.takeDataToChat.receiver) {
            currentUser = i
            this.$root.$data.settakeDataToChat(null)
            return
          }
        }
        this.chatMailBox.unshift({
          receiver: this.$root.$data.state.chat.takeDataToChat.receiver,
          newMessageCount: 0,
          time: this.$root.$data.state.chat.takeDataToChat.time
        })
        currentUser = 0
        this.$root.$data.settakeDataToChat(null)
      }
    })
    await this.getProfileListByUserIDList()
    this.selected = currentUser
  },
  data () {
    return {
      selected: null,
      selectedUser: {},
      chatMailBox: [],
      mandatory: false
    }
  },
  watch: {
    selected (newSelected, oldSelected) {
      this.$root.$data.clearNewMessageCountAction()
      this.mandatory = true
      this.selectedUser = {
        receiver: this.chatMailBox[newSelected].receiver,
        receiverName: this.chatMailBox[newSelected].receiverName,
        receiverAvatar: this.chatMailBox[newSelected].receiverAvatar,
        nickName: this.chatMailBox[newSelected].nickName
      }
      this.$emit('getCurrent', this.selectedUser)
    },
    chatFlag: function (newData, oldData) {
      if (newData) {
        this.getProfile(this.chatDTO.sender).then((response) => {
          const data = response.data
          const oldSelectedChatMailBox = (this.selected != null) ? this.chatMailBox[this.selected] : null
          for (let i = 0; i < this.chatMailBox.length; i++) {
            if (this.chatMailBox[i].receiver === this.chatDTO.sender) {
              this.chatMailBox.splice(i, 1)
              break
            }
          }
          this.chatMailBox.unshift({
            receiver: this.chatDTO.sender,
            newMessageCount: 1,
            receiverName: data.firstName + ' ' + data.lastName,
            receiverAvatar: data.photoURL,
            nickName: data.nickName,
            time: this.chatDTO.time
          })
          if (oldSelectedChatMailBox != null) {
            for (let i = 0; i < this.chatMailBox.length; i++) {
              if (this.chatMailBox[i].receiver === oldSelectedChatMailBox.receiver) {
                this.selected = i
                break
              }
            }
          }
        })
      }
    },
    toChatListTopCurrent: function (newData, oldData) {
      if (this.chatMailBox[0].receiver !== newData.receiver) {
        for (let i = 0; i < this.chatMailBox.length; i++) {
          if (this.chatMailBox[i].receiver === newData.receiver) {
            const temp = this.chatMailBox.splice(i, 1)
            this.chatMailBox.unshift(temp[0])
            this.selected = 0
            break
          }
        }
      }
    }
  },
  methods: {
    clearchatMailBoxNewMessageCountAction (index) {
      this.selected = index
      this.chatMailBox[index].newMessageCount = 0
    },

    findChatMailBox () {
      return chatAPI.findChatMailBox().then((response) => {
        const chatMailBoxList = response.data
        const arrangeChatMailBoxList = []
        for (let i = 0; i < chatMailBoxList.length; i++) {
          if (chatMailBoxList[i].receiver === this.$root.$data.state.common.userID) {
            arrangeChatMailBoxList.push({
              receiver: chatMailBoxList[i].sender,
              newMessageCount: chatMailBoxList[i].newMessageCount,
              time: chatMailBoxList[i].time
            })
          }
        }
        for (let i = 0; i < chatMailBoxList.length; i++) {
          if (chatMailBoxList[i].sender === this.$root.$data.state.common.userID) {
            if (this.isDuplicateReceiver(chatMailBoxList[i], arrangeChatMailBoxList)) { continue }
            arrangeChatMailBoxList.push({
              receiver: chatMailBoxList[i].receiver,
              newMessageCount: 0,
              time: chatMailBoxList[i].time
            })
          }
        }
        arrangeChatMailBoxList.sort((a, b) => {
          return -(new Date(a.time).getTime() - new Date(b.time).getTime())
        })
        this.chatMailBox = arrangeChatMailBoxList
      })
    },
    isDuplicateReceiver (chatMailBoxList, arrangeChatMailBox) {
      for (let j = 0; j < arrangeChatMailBox.length; j++) {
        if (chatMailBoxList.receiver === arrangeChatMailBox[j].receiver) {
          if (new Date(chatMailBoxList.time).getTime() - new Date(arrangeChatMailBox[j].time).getTime() > 0) { arrangeChatMailBox[j].time = chatMailBoxList.time }
          return true
        }
      }
      return false
    },
    getProfileListByUserIDList () {
      const userIDList = this.chatMailBox.map(chatMailBox => chatMailBox.receiver)
      return userAPI.getProfileListByUserIDList(userIDList).then((response) => {
        const data = response.data
        for (let i = 0; i < this.chatMailBox.length; i++) {
          for (let j = 0; j < data.length; j++) {
            if (this.chatMailBox[i].receiver === data[j].userID) {
              this.$set(this.chatMailBox[i], 'receiverName', data[j].firstName + ' ' + data[j].lastName)
              this.$set(this.chatMailBox[i], 'receiverAvatar', data[j].photoURL)
              this.$set(this.chatMailBox[i], 'nickName', data[j].nickName)
              break
            }
          }
        }
      })
    },
    getProfile (userID) {
      return userAPI.getProfile(userID)
    }
  }
}
</script>
<style scoped>
</style>
