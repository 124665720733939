<template>
  <div>
    <v-row>
      <v-col>
        <v-card flat>
          <v-card-text>
            <v-card-title>檢舉</v-card-title>
            <v-row>
              <v-col>
                <v-data-table
                  :headers="reportTableHeaders"
                  :items="reports"
                  :sort-by="['status']"
                  class="elevation-1"
                >
                  <template v-slot:item.postID="{ item }">
                    <a
                      target="_blank"
                      :href="`${frontEndServer}/post/${item.postID}`"
                      >{{ item.postID }}</a
                    >
                  </template>
                  <template v-slot:item.status="{ item }">
                    {{ item.status ? "已處理" : "未處理" }}
                  </template>
                  <template v-slot:item.auditResults="{ item }">
                    {{ item.auditResults ? "無異常" : "異常" }}
                  </template>
                  <template v-slot:item.actions="{ item }">
                    <div v-if="!item.status">
                      <v-icon
                        class="mr-2"
                        @click="
                          reviseAuditResultsForPostReport(
                            item.id,
                            item.postID,
                            true
                          )
                        "
                      >
                        mdi-circle-outline
                      </v-icon>
                      <v-icon
                        @click="
                          reviseAuditResultsForPostReport(
                            item.id,
                            item.postID,
                            false
                          )
                        "
                      >
                        mdi-close-thick
                      </v-icon>
                    </div>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import postAPI from '../../../apis/post'

import serverIP from '../../../apis/common'

export default {
  name: 'ReportList',
  data () {
    return {
      reportTableHeaders: [
        { text: 'ID', value: 'id' },
        { text: '藏品ID', value: 'postID' },
        { text: '檢舉人', value: 'whistleblower' },
        { text: '審核狀態', value: 'status' },
        { text: '審核結果', value: 'auditResults' },
        { text: '審核', value: 'actions', sortable: false }
      ],
      reports: [],
      frontEndServer: null
    }
  },
  mounted () {
    this.frontEndServer = serverIP.frontEndServer
    this.readReportForPost()
  },
  methods: {
    readReportForPost () {
      postAPI.readReportForPost().then((response) => {
        this.reports = response.data
      })
    },
    reviseAuditResultsForPostReport (id, postID, auditResults) {
      const result = auditResults ? '無異常' : '異常'
      const reportDS = {
        id: id,
        postID: postID,
        auditResults: auditResults
      }
      if (confirm(`確認審核結果 - ${result}`) === true) {
        postAPI.reviseAuditResultsForPostReport(reportDS).then(() => {
          this.readReportForPost()
        })
      }
    }
  }
}
</script>
