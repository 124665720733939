import Vue from 'vue'
import VueRouter from 'vue-router'
import LoginAndRegister from '../views/User/LoginAndRegister.vue'
import SysTemplate from '../views/Common/Layout/SysTemplate.vue'
import Home from '../views/Post/Home.vue'
import Post from '../views/Post/Post.vue'
import Profile from '../views/Directory/Profile.vue'
import CreatePost from '../views/Post/CreatePost.vue'
import Setting from '../views/User/Setting.vue'
import Search from '../views/Post/Search.vue'
import Chat from '../views/Chat/Chat.vue'
import Privacy from '../views/Other/Privacy.vue'
import About from '../views/Other/About.vue'
import ThirdPaymentNewebPay from '../views/Pay/ThirdPaymentNewebPay.vue'
import PaidPlan from '../views/Pay/PaidPlan.vue'
import ThirdPartyLogin from '../views/User/ThirdPartyLogin.vue'
import Admin from '../views/Other/Admin.vue'
import RedirectToExternalLink from '../views/Common/RedirectToExternalLink.vue'

Vue.use(VueRouter)

const routes = [

  {
    path: '/',
    redirect: '/home',
    component: SysTemplate,
    children: [
      { path: '/login', component: LoginAndRegister, props: route => ({ defaultStep: route.query.defaultStep }) },
      { path: '/home', component: Home },
      { path: '/post/:id', component: Post },
      { path: '/create_post', component: CreatePost },
      { path: '/profile/:nickName', component: Profile },
      { path: '/setting', component: Setting },
      {
        path: '/search',
        component: Search,
        props: route => ({ searchText: route.query.searchText })
      },
      { path: '/chat', component: Chat },
      { path: '/privacy', component: Privacy },
      { path: '/about', component: About },
      { path: '/paid_plan', component: PaidPlan },
      { path: '/third_payment', component: ThirdPaymentNewebPay },
      { path: '/third_party_login/:provider', component: ThirdPartyLogin }
    ]
  },
  { path: '/admin', component: Admin },
  { path: '/redirect_to_external_link', component: RedirectToExternalLink }

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
