<template lang="pug">
v-dialog(max-width="1200px" v-model='dialog' )
  template(v-slot:activator='{ on, attrs }')
    .bg-white.rounded.flex.justify-center.items-center.w-9.h-9(v-bind='attrs', v-on='on')
      v-tooltip(top)
        template(v-slot:activator="{ on, attrs }")
          img.w-5.h-5(src='@/assets/profile/edit_profile.png',v-bind="attrs", v-on="on")
        span 編輯
  v-card.p-2
    .flex.flex-col.p-3.relative.w-full.justify-between(class="md:flex-row")
      .flex.flex-col.relative.flex-auto(class="md:flex-4")
        .flex.flex-col.relative.photo(style='width: 100%;')
          .bg-black.w-full.flex-1.rounded
            img(style='width: 100%; height: 100%; aspect-ratio:1; object-fit: contain' :src='pictureURL')
          .flex.mt-2.relative.flex-wrap
            div( v-for="(smallPic,index) in post.pictureList")
              img.h-12.w-12.mr-2.cursor-pointer.mb-2(:src='smallPic.pictureURL' style="object-fit:cover" @click="pictureURL=smallPic.pictureURL")
      .h-12(class="md:w-6")
      .flex.flex-col.justify-between.flex-auto(class="md:flex-5")
        .flex.flex-col
          .max-w-min
            select-directory-for-upload-file(:fileID='fileID' @close='close' @readDirectory='readDirectory')
          .flex.flex-col.w-full
            input.w-full.text-2xl.mt-6(v-model='post.title' label='標題')
            v-divider.mt-2.mb-6
            v-textarea(v-model='post.content' outlined label='內容')
            .input-pri.input-outline.bg-gray-100.p-2.rounded.mr-4.w-full
              input.w-full(ref="sendMsg" v-model='post.url' placeholder="連結")
        .flex.justify-between.mt-2
          .btn.bg-pri(@click='deleteFile') 刪除本藏品
          .flex
            .btn.bg-lightgray.mr-2(@click='close') 取消編輯
            .btn.bg-pri(@click='revisePost') 儲存
</template>

<script>
import SelectDirectoryForUploadFile from './ReviseUploadFile/SelectDirectoryForUploadFile.vue'

import directoryAPI from '../../../apis/directory'
import postAPI from '../../../apis/post'

export default {
  components: { SelectDirectoryForUploadFile },
  name: 'ReviseUploadFile',
  props: ['postID', 'fileID'],
  data () {
    return {
      dialog: false,
      pictureURL: '',
      post: {
        id: null,
        title: null,
        content: null,
        url: null
      }
    }
  },
  watch: {
    dialog: function (newDialog, oldDialog) {
      if (newDialog) { this.readPost() }
    }
  },
  methods: {
    close () {
      this.dialog = false
    },
    deletePicture (index) {
      if (this.post.pictureList.length > 0) {
        this.post.pictureList.splice(index, 1)
        Object.assign(this.post, this.post)
        this.pictureURL = this.post.pictureList[0].pictureURL
        this.revisePost()
      }
    },
    readPost () {
      postAPI.readPostForUser(this.postID).then((response) => {
        const data = response.data
        this.post.id = data.id
        this.post.title = data.title
        this.post.content = data.content
        this.post.url = data.url
        this.post.pictureList = data.pictureList
        this.pictureURL = this.post.pictureList[0].pictureURL
      })
    },
    revisePost () {
      postAPI.revisePost(this.post).then((response) => {
        this.close()
      })
    },
    readDirectory () {
      this.$emit('readDirectory')
    },
    deleteFile () {
      if (confirm('將永遠移除此檔案，此動作無法復原，確認刪除?\n\n※注意：此動作會花費較久的時間，請等待成功提示出現※') === true) {
        const directoryInputDS = {
          postID: this.postID,
          fileID: this.fileID
        }
        directoryAPI.removeUploadFile(directoryInputDS).then((response) => {
          if (response) {
            alert('刪除成功')
            this.$emit('readDirectory')
            this.close()
          }
        })
      }
    }
  }
}
</script>

<style scoped>
input{
  background-color: transparent;
  outline: none;
  border: 0px solid;
}
*:focus {
    outline: none;
}
.photo{
  max-height: max-content;
}
 /* mediaQuery  */
@media only screen and (max-width: 650px) {
  .photo{
    height: 350px !important;
  }
}
</style>
