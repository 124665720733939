<template lang="pug">
.chat.relative.chat-bg.overflow-hidden.h-full
  .flex.p-2.h-full(class="md:p-8" )
    .h-full.bg-white.rounded.overflow-y-scroll(class="md:min-w-max" style="flex:1")
      chat-user-list(:chatFlag='$root.$data.state.chat.chatFlag' :chatDTO='$root.$data.state.chat.chatDTO' :toChatListTopCurrent='toChatListTopCurrent' @getCurrent='getCurrent')
    .m-1(class="md:m-3")
    .h-full.bg-white.rounded.w-full.shadow(style="flex:4")
      chat-dialogue(:chatFlag='$root.$data.state.chat.chatFlag' :chatDTO='$root.$data.state.chat.chatDTO' :current='current' @toChatListTop='toChatListTop')
</template>

<script>
import ChatUserList from './Chat/ChatUserList'
import ChatDialogue from './Chat/ChatDialogue'

export default {
  name: 'Chat',
  components: { ChatUserList, ChatDialogue },
  mounted () {
  },
  data () {
    return {
      current: {},
      toChatListTopCurrent: {}
    }
  },
  methods: {
    getCurrent (selected) {
      this.current = selected
    },
    toChatListTop (current) {
      this.toChatListTopCurrent = current
    }
  }
}
</script>
<style scoped>
.border {
  border-right: 1px solid grey;
}

.chat-bg{
  background-image: url('~@/assets/background/profile_bg.png');
  background-position: top;
  background-size: cover;
}
</style>
