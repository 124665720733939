<template lang="pug">
.setting-bg.w-screen.h-full.flex.justify-center.p-2(class="md:p-10")
  v-tabs(vertical style="max-width: 90%;height:fit-content;")
    v-tab 公開個人檔案
    v-tab(v-if="this.profile.provider==='local'") 帳號設定
    v-tab 付款資訊
    v-tab-item
      v-card.bg-red-400(flat='')
        v-card-text
          h3.font-bold.mb-3 公開個人檔案
          h2 頭像
          v-avatar.overflow-visible.relative.cursor-pointer(size="84")
            img(:src="avatarImage ? avatarImage: profile.photoURL" style="object-fit: cover;" @click="openFileBrowser")
            .absolute.pointer-events-none.p-2.bg-white.rounded-full.bottom-0.right-0.shadow
              v-icon(size="20") mdi-pencil
            .absolute(style="display: none;")
              v-file-input#avatarFile(truncate-length="1" hide-input v-model='uploadFile' @change='previewImage', label='頭像')
          v-row.mt-2
            v-col(cols='6')
              h2 姓氏
              .input-pri
                input.w-full(v-model='profile.firstName', label='姓氏')
            v-col(cols='6')
              h2 名字
              .input-pri
                input.w-full(v-model='profile.lastName', label='名字')
          v-row
            v-col
              h2 個人簡介
              .input-pri
                textarea.w-full(v-model='profile.introduction', label='個人簡介')
          v-row
            v-col
              h2 個人網站
              .input-pri
                textarea.w-full(v-model='profile.siteURL', label='個人網站')
          .flex.mt-4
            .btn.mr-2(@click='getProfile') 重設
            .btn.bg-pri(@click='reviseProfile') 儲存
    v-tab-item(v-if="this.profile.provider==='local'")
      v-card(flat='')
        v-card-text
          h3.font-bold 變更密碼
          p 在此設定你的新密碼，以及進行重要帳號變更
          v-row
            v-col
              h2 目前的密碼
              .input-pri
                input.w-full(v-model='changePasswordDTO.originalPassword', :append-icon="passwordVisible ? 'mdi-eye' : 'mdi-eye-off'", :type="passwordVisible ? 'text' : 'password'", @click:append='passwordVisible = !passwordVisible', label='目前的密碼')
              h2 新密碼
              .input-pri
                input.w-full(v-model='changePasswordDTO.firstPassword', :append-icon="passwordVisible ? 'mdi-eye' : 'mdi-eye-off'", :type="passwordVisible ? 'text' : 'password'", @click:append='passwordVisible = !passwordVisible', label='新密碼')
              h2 再次輸入新密碼
              .input-pri
                input.w-full(v-model='changePasswordDTO.secondPassword', :append-icon="passwordVisible ? 'mdi-eye' : 'mdi-eye-off'", :type="passwordVisible ? 'text' : 'password'", @click:append='passwordVisible = !passwordVisible', label='再次輸入新密碼')
              .btn.mt-4.bg-pri(@click='revisePassword') 儲存
    v-tab-item
      v-card(flat='')
        v-card-text
          h3.font-bold 付款資訊
          v-row
            v-col
              v-data-table.elevation-1(:headers='paymentsTableHeaders', :items='payments')
</template>
<script>
import userAPI from '../../apis/user'
import payAPI from '../../apis/pay'

export default {
  name: 'Setting',
  data () {
    return {
      profile: {
        photoURL: '',
        firstName: '',
        lastName: '',
        introduction: '',
        siteURL: ''
      },
      uploadFile: null,
      avatarImage: null,
      passwordVisible: false,
      changePasswordDTO: {
        originalPassword: '',
        firstPassword: '',
        secondPassword: ''
      },
      payments: null,
      paymentsTableHeaders: [
        { text: 'ID', value: 'id' },
        { text: '交易日期', value: 'tradeDate' },
        { text: '狀態', value: 'state' },
        { text: '期間', value: 'during' },
        { text: '可上傳收藏品件數', value: 'postCount' },
        { text: '方案名稱', value: 'item' },
        { text: '價格', value: 'price' },
        { text: '開始時間', value: 'startDate' },
        { text: '結束時間', value: 'endDate' }
      ]
    }
  },
  mounted () {
    this.getProfile()
    this.searchUserOrder()
  },
  methods: {
    openFileBrowser () {
      document.getElementById('avatarFile').click()
    },
    previewImage () {
      const reader = new FileReader()
      reader.onload = (e) => {
        this.avatarImage = e.target.result
      }
      reader.readAsDataURL(this.uploadFile)
      this.uploadAvatar()
    },
    clearChangePasswordDTO () {
      this.changePasswordDTO = {
        originalPassword: '',
        firstPassword: '',
        secondPassword: ''
      }
    },

    updateCommonProfile () {
      this.$root.$data.getProfile(this.$root.$data.state.common.userID)
    },
    getProfile () {
      userAPI.getProfile(this.$root.$data.state.common.userID).then((response) => {
        this.profile = response.data
        this.avatarImage = this.profile.photoURL
        this.uploadFile = null
      })
    },
    uploadAvatar () {
      const formData = new FormData()
      formData.append('uploadFile', this.uploadFile)
      userAPI.updateAvatar(formData).then((response) => {
        if (response.data === true) {
          alert('頭像修改成功')
          this.getProfile()
          this.updateCommonProfile()
        } else {
          alert('頭像修改失敗')
        }
      })
    },
    reviseProfile () {
      userAPI.reviseProfile(this.profile).then((response) => {
        if (response.data === true) {
          alert('修改成功')
          this.getProfile()
          this.updateCommonProfile()
        } else {
          alert('修改失敗')
        }
      })
    },
    revisePassword () {
      userAPI.revisePassword(this.changePasswordDTO).then((response) => {
        if (response.data === true) {
          this.clearChangePasswordDTO()
          alert('修改成功')
          this.getProfile()
        } else {
          alert('修改失敗，可能原因：\n1.密碼輸入二次不同\n2.密碼需要包含大小寫英文和數字')
        }
      })
    },
    searchUserOrder () {
      payAPI.searchUserOrder(this.$root.$data.state.common.userID).then((response) => {
        const data = response.data
        this.payments = data.map((order) => {
          const tradeDateTemp = new Date(order.tradeDate)
          const startDateTemp = new Date(order.startDate)
          const endDateTemp = new Date(order.endDate)
          return {
            id: order.id,
            tradeDate: `${tradeDateTemp.getFullYear()}/${tradeDateTemp.getMonth() + 1}/${tradeDateTemp.getDate()} ${tradeDateTemp.getHours()}:${tradeDateTemp.getMinutes()}`,
            state: order.state,
            item: order.item,
            postCount: order.postCount,
            price: order.price,
            during: order.during,
            startDate: order.startDate !== null ? `${startDateTemp.getFullYear()}/${startDateTemp.getMonth() + 1}/${startDateTemp.getDate()} ${startDateTemp.getHours()}:${startDateTemp.getMinutes()}` : null,
            endDate: order.startDate !== null ? `${endDateTemp.getFullYear()}/${endDateTemp.getMonth() + 1}/${endDateTemp.getDate()} ${endDateTemp.getHours()}:${endDateTemp.getMinutes()}` : null
          }
        })
      })
    }
  }
}
</script>

<style>
.setting-bg{
  background-image: url('~@/assets/background/profile_bg.png');
  background-position: top;
  background-size: cover;
}
.input-pri input{
    background-color: transparent;
    border: 0px transparent solid;
}
.input-pri input:focus-visible {
  outline: none;
}
.input-pri textarea:focus-visible {
  outline: none;
}
</style>
