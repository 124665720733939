import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import { VueMasonryPlugin } from 'vue-masonry'
import store from './store/store'
import './assets/tailwind.css'
import './index.css'
import 'drift-zoom/dist/drift-basic.css'

Vue.config.productionTip = false
Vue.use(VueMasonryPlugin)

async function init () {
  const userToken = localStorage.getItem('user-token')
  if (userToken !== null) {
    const userTokenTimout = localStorage.getItem('user-token-timeout')
    if (userToken === null || new Date().getTime() > userTokenTimout) {
      localStorage.removeItem('user-token')
      localStorage.removeItem('user-token-timeout')
    } else {
      await store.init()
    }
  }
}

init().then(() => {
  new Vue({
    router,
    vuetify,
    data: store,
    render: h => h(App)
  }).$mount('#app')
})
