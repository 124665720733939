import requestHelper from './helper'

import axios from 'axios'
import serverIP from './common'

const directoryURL = '/directory'

const directoryAPI = {
  createDirectory: directory => (requestHelper.post(`${directoryURL}/dir`, directory)),
  readDirectory: directory => (axios.post(`${serverIP.backEndServer}${directoryURL}/readDirectory`, directory)),
  findDirectoryByID: id => (requestHelper.get(`${directoryURL}/findDirectoryByID/${id}`)),
  reviseDirectory: directoryDS => (requestHelper.post(`${directoryURL}/reviseDirectory`, directoryDS)),
  deleteDirectory: directory => (requestHelper.post(`${directoryURL}/deleteDirectory`, directory)),
  deleteUploadDirectory: directory => (requestHelper.post(`${directoryURL}/deleteUploadDirectory`, directory)),
  addFile: directory => (requestHelper.post(`${directoryURL}/file`, directory)),
  deleteFile: fileID => (requestHelper.delete(`${directoryURL}/deleteFile/${fileID}`)),
  removeUploadFile: directoryInputDS => (requestHelper.post(`${directoryURL}/removeUploadFile`, directoryInputDS)),
  moveUploadFile: directoryInputDS => (requestHelper.post(`${directoryURL}/moveUploadFile`, directoryInputDS))
}

export default directoryAPI
