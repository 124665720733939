<template>
  <v-footer class="py-3 fixed bottom-0 w-full h-56px">
    <span class="ml-auto"><a href="#" @click.prevent="$router.push('/about')">關於我們</a> | <a href="#" @click.prevent="$router.push('/paid_plan')">付費方案</a> | <a href="#" @click.prevent="$router.push('/privacy')">會員條款</a> | 藏家會 &copy;2022</span>
  </v-footer>
</template>
<script>

export default {
  name: '',
  data () {
    return {

    }
  },
  created () {

  },
  methods: {

  }
}
</script>
<style scoped>
footer.v-footer span.ml-auto {
  font-family: 'Noto Serif TC', serif;
  font-size: 0.75rem !important;
  font-weight: 600;
  letter-spacing: 0.1666666667em !important;
  line-height: 2rem;
  text-transform: uppercase;
  /* font-family: "Roboto", sans-serif !important; */
}

</style>
