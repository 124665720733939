<template lang="pug">
v-dialog(v-model='dialog' width='400')
  template(v-slot:activator='{ on, attrs }')
    .btn.bg-white(v-if="btnStyle" v-bind='attrs' v-on='on') 建立目錄
    .flex.px-4.items-center(v-else="btnStyle" v-bind='attrs' v-on='on')
      .rounded-full.bg-pri.h-4.w-4.text-center.flex.justify-center.items-center.mr-2
        .text-base(style="margin-bottom:1px") +
      .text-base.my-4 建立目錄
  v-card.p-8
    .text-2xl.font-medium 建立目錄
    .h-2
    .input-pri.input-outline.bg-gray-100.p-2.rounded.mr-4.w-full
      input.w-full(ref="sendMsg" v-model='directoryInputDS.directoryName' placeholder="名稱")
    .h-8( v-if="!currentPath.startsWith('home/track')")
    .flex.justify-between( v-if="!currentPath.startsWith('home/track')")
      .text-base.font-medium 是否公開收藏品
      .flex
        input(v-model='directoryInputDS.share' type='checkbox' class='ios8-switch' id='checkbox-3')
        label( for='checkbox-3')
    .h-8
    .flex.justify-end
      .btn.bg-pri(color='primary' text='' @click='createDirectory')  建立

</template>
<script>
import directoryAPI from '../../../apis/directory'

export default {
  name: 'CreateDirectory',
  props: {
    currentPath: {
      default: ''
    },
    btnStyle: {
      default: false
    }
  },
  data () {
    return {
      dialog: false,
      directoryInputDS: {
        path: '',
        directoryName: '',
        share: true
      }
    }
  },
  methods: {
    createDirectory () {
      this.directoryInputDS.path = this.currentPath
      directoryAPI.createDirectory(this.directoryInputDS).then((response) => {
        this.$emit('refreshDirectory')
        this.directoryInputDS.path = ''
        this.directoryInputDS.directoryName = ''
        this.directoryInputDS.share = false
        this.dialog = false
      })
    }
  }
}
</script>
<style scoped>
input[type="checkbox"].ios8-switch {
    position: absolute;
    margin: 8px 0 0 16px;
}
input[type="checkbox"].ios8-switch + label {
    position: relative;
    padding: 5px 0 0 50px;
    line-height: 2.0em;
}
input[type="checkbox"].ios8-switch + label:before {
    content: "";
    position: absolute;
    display: block;
    left: 0;
    top: 0;
    width: 40px; /* x*5 */
    height: 24px; /* x*3 */
    border-radius: 16px; /* x*2 */
    background: #fff;
    border: 1px solid #d9d9d9;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
}
input[type="checkbox"].ios8-switch + label:after {
    content: "";
    position: absolute;
    display: block;
    left: 2px;
    top: 2px;
    width: 20px; /* x*3 */
    height: 20px; /* x*3 */
    border-radius: 16px; /* x*2 */
    background: #fff;
    border: 1px solid #d9d9d9;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
}
input[type="checkbox"].ios8-switch + label:hover:after {
    box-shadow: 0 0 5px rgba(0,0,0,0.2);
}
input[type="checkbox"].ios8-switch:checked + label:after {
    margin-left: 16px;
}
input[type="checkbox"].ios8-switch:checked + label:before {
    background: #C2321F;
}

/* SMALL */

input[type="checkbox"].ios8-switch-sm {
    margin: 5px 0 0 10px;
}
input[type="checkbox"].ios8-switch-sm + label {
    position: relative;
    padding: 0 0 0 32px;
    line-height: 1.3em;
}
input[type="checkbox"].ios8-switch-sm + label:before {
    width: 25px; /* x*5 */
    height: 15px; /* x*3 */
    border-radius: 10px; /* x*2 */
}
input[type="checkbox"].ios8-switch-sm + label:after {
    width: 15px; /* x*3 */
    height: 15px; /* x*3 */
    border-radius: 10px; /* x*2 */
}
input[type="checkbox"].ios8-switch-sm + label:hover:after {
    box-shadow: 0 0 3px rgba(0,0,0,0.3);
}
input[type="checkbox"].ios8-switch-sm:checked + label:after {
    margin-left: 10px; /* x*2 */
}

/* LARGE */

input[type="checkbox"].ios8-switch-lg {
    margin: 10px 0 0 20px;
}
input[type="checkbox"].ios8-switch-lg + label {
    position: relative;
    padding: 7px 0 0 60px;
    line-height: 2.3em;
}
input[type="checkbox"].ios8-switch-lg + label:before {
    width: 50px; /* x*5 */
    height: 30px; /* x*3 */
    border-radius: 20px; /* x*2 */
}
input[type="checkbox"].ios8-switch-lg + label:after {
    width: 30px; /* x*3 */
    height: 30px; /* x*3 */
    border-radius: 20px; /* x*2 */
}
input[type="checkbox"].ios8-switch-lg + label:hover:after {
    box-shadow: 0 0 8px rgba(0,0,0,0.3);
}
input[type="checkbox"].ios8-switch-lg:checked + label:after {
    margin-left: 20px; /* x*2 */
}
</style>
