import requestHelper from './helper'

const chatURL = '/chat'

const chatAPI = {
  create: chatDS => (requestHelper.post(`${chatURL}/create`, chatDS)),
  findChatMailBox: () => (requestHelper.get(`${chatURL}/findChatMailBox`)),
  findChat: other => (requestHelper.post(`${chatURL}/findChat`, other)),
  getUserCountFromMessages: () => (requestHelper.get(`${chatURL}/getUserCountFromMessages`))
}

export default chatAPI
