<template lang="pug">
v-row.my-8.profile-bg.bg-white.p-5.relative.shadow
  .absolute.left-0.top-0
    img.w-14(src="~/@/assets/profile/profile_decoration.png")
  .flex.items-center
    v-avatar.mr-2(size="120")
      v-img(:src="profile.photoURL")
    .text-4xl.font-bold(class="md:hidden") {{profile.firstName + ' ' + profile.lastName }}

  .w-6
  .flex.flex-col.items-start.justify-center
    .text-4xl.font-bold.mr-2.hidden(class="md:block") {{profile.firstName + ' ' + profile.lastName }}
    .text-base {{ profile.introduction + ', ' }}
    a(@click="openTab(profile.siteURL)") {{ profile.siteURL }}
  .flex-1
  .box.mt-1(v-if="$root.$data.state.common.isLogin && userID!=readUserID",  @click="toChat")
    .btn.flex.items-center.bg-white
      img.w-5.h-5(src="@/assets/home/message_icon.png")
      .w-2
      .font-medium(@click="toChat") 開始對談
  .box.mt-1(v-if="$root.$data.state.common.isLogin && userID==readUserID")
    .btn.flex.items-center.bg-white
      img.w-3.h-3(src="@/assets/profile/edit_profile.png")
      .w-2
      .font-medium(@click="toSetting") 編輯個人檔案
</template>
<script>
import serverIP from '../../../apis/common'

export default {
  name: 'ProfileHead',
  props: ['profile', 'userID', 'readUserID'],
  data () {
    return {
      frontEndServer: null
    }
  },
  mounted () {
    this.frontEndServer = serverIP.frontEndServer
  },
  methods: {
    openTab (url) {
      if (window.confirm(`即將開啟下列連結: ${url}，提醒您，不明連結可能包含惡意程式`)) {
        window.open(url, '_blank')
      }
    },
    toSetting () {
      this.$router.push('/setting')
    },
    toChat () {
      this.$emit('toChat')
    }
  }
}
</script>

<style scoped>
.profile-bg{
  background-image: url('~@/assets/background/chat_title_bg.png');
  background-position: center;
  background-size: cover;
}
</style>
