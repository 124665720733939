<template lang="pug">
v-dialog(v-model='dialog' width="500")
  template(v-slot:activator='{ on, attrs }')
    .bg-white.rounded.flex.justify-center.items-center.w-9.h-9(v-bind='attrs', v-on='on')
      v-tooltip(top)
        template(v-slot:activator="{ on, attrs }")
          img.w-5.h-5(src='@/assets/home/delete.png',v-bind="attrs", v-on="on")
        span 刪除
  v-card
    v-col
      v-col
        div(style='width: 100%; height: 300px; background: #000')
          img(style='width: 100%; height: 100%; object-fit: contain' :src='pictureURL')
      v-col
        .flex.flex-col
          h3 刪除
          p 從您的最愛收藏中移除
          .flex.justify-end
            .btn.btn-pri.bg-pri.mr-2(@click='deleteFile') 刪除
            .btn.btn-pri(@click='close') 取消
    v-divider
</template>
<script>
import directoryAPI from '../../../apis/directory'
import postAPI from '../../../apis/post'

export default {
  name: 'ReviseTrackFile',
  props: ['postID', 'fileID', 'currentPath'],
  data () {
    return {
      dialog: false,
      pictureURL: '',
      post: {
        id: null,
        title: null,
        content: null,
        url: null
      }
    }
  },
  watch: {
    dialog: function (newDialog, oldDialog) {
      if (newDialog) {
        this.readPost()
      }
    }
  },
  methods: {
    close () {
      this.dialog = false
    },

    readPost () {
      postAPI.readPostForUser(this.postID).then((response) => {
        const data = response.data
        this.post.id = data.id
        this.post.title = data.title
        this.post.content = data.content
        this.post.url = data.url
        this.pictureURL = data.pictureList[0].pictureURL
      })
    },
    deleteFile () {
      if (confirm('確認刪除?') === true) {
        directoryAPI.deleteFile(this.fileID).then((response) => {
          this.close()
          this.$emit('readDirectoryAndClear')
        })
      }
    }
  }
}
</script>
