<template>
  <div v-masonry transition-duration="0s" item-selector=".item" fit-width="true">
    <div
      class="item cursor-pointer"
      v-masonry-tile
      v-for="(post, index) in posts"
      :key="'post' + index"
    >
      <img
        class="imageItem"
        :src="post.pictureURL"
        @click="imgClick(post.id)"
      />
      <masonry-image-select
        v-if="
          $root.$data.state.common.isLogin &&
          $router.currentRoute.path !== `/profile/${$root.$data.state.common.userID}`
        "
        class="saveBtn"
        rounded
        color="red"
        dark
        :post="post"
        :readUserID="readUserID"
        :currentPath="currentPath"
        @readDirectoryAndClear="readDirectoryAndClear"
        @readDirectory="readDirectory"
      ></masonry-image-select>
    </div>
  </div>
</template>

<script>
import MasonryImageSelect from './MasonryImageSelect.vue'

export default {
  components: {
    MasonryImageSelect
  },
  name: 'MasonryImage',
  props: ['posts', 'currentPath', 'readUserID'],
  methods: {
    imgClick (id) {
      this.$emit('imgClick', id)
    },
    readDirectory () {
      this.$emit('readDirectory')
    },
    readDirectoryAndClear () {
      this.$emit('readDirectoryAndClear')
    }
  }
}
</script>
<style scoped>
.item {
  position: relative;
  width: 300px;
}

.imageItem {
  padding: 4px;
  width: 100%;
  height: auto;
  border-radius: 4px;
  transition: .5s;
  cursor: pointer;
}

.saveBtn {
  position: absolute;
  bottom: 16px;
  right: 16px;
  display: none;
  cursor: pointer;
}

.item:hover > .imageItem, .item:active > .imageItem {
  filter: brightness(50%);
}

.item:hover > .saveBtn, .item:active > .saveBtn {
  display: flex;
}

/* .imageItem:hover + .saveBtn,
.imageItem:active + .saveBtn,
.saveBtn:hover,
.saveBtn:active {
  display: flex;
} */
</style>
