<template>
  <div>
    <v-card-title>產品列表</v-card-title>
    <v-card-text>
      <v-row>
          <v-col>
        <v-dialog v-model="dialog">
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on">新增產品</v-btn>
          </template>

          <v-card>
            <v-card-title class="text-h5 grey lighten-2">
              新增產品
            </v-card-title>

            <v-card-text>
              <v-row>
                <v-col>
                  <v-text-field
                    v-model="product.item"
                    label="產品名稱"
                  ></v-text-field>
                  <v-text-field
                    v-model="product.postCount"
                    label="藏品件數"
                  ></v-text-field>
                  <v-text-field
                    v-model="product.price"
                    label="產品價格"
                  ></v-text-field>
                  <v-text-field
                    v-model="product.during"
                    label="產品週期(天)"
                  ></v-text-field>
                  <v-checkbox
                    v-model="product.isVisible"
                    label="可見"
                  ></v-checkbox>
                  <v-select
                    v-model="select"
                    :items="items"
                    item-text="state"
                    item-value="abbr"
                    label="價格類型"
                    return-object
                  ></v-select>
                </v-col>
              </v-row>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" text @click="createProduct"> 新增 </v-btn>
              <v-btn color="primary" text @click="close"> 關閉 </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
            <v-data-table
                  :headers="productsTableHeaders"
                  :items="products"
                  class="elevation-1"
                >
                  <template v-slot:item.actions="{ item }">
                    <v-icon @click="reviseProductVisible(item)">
                      mdi-pencil
                    </v-icon>
                  </template>
                </v-data-table>
        </v-col>
      </v-row>
    </v-card-text>
  </div>
</template>
<script>
import payAPI from '../../../apis/pay'

export default {
  name: 'ProductList',
  data () {
    return {
      dialog: false,
      product: {
        item: null,
        postCount: null,
        price: null,
        during: null,
        isVisible: false,
        priceType: ''
      },
      select: { state: '首購', abbr: 'first_purchase' },
      items: [
        { state: '首購', abbr: 'first_purchase' },
        { state: '特價', abbr: 'special_offer' },
        { state: '一般購買', abbr: 'general_purchase' }
      ],
      productsTableHeaders: [
        { text: 'ID', value: 'id' },
        { text: '項目', value: 'item' },
        { text: '藏品件數', value: 'postCount' },
        { text: '價格', value: 'price' },
        { text: '週期(天)', value: 'during' },
        { text: '可見', value: 'isVisible' },
        { text: '建立日期', value: 'createDate' },
        { text: '價格類型', value: 'priceType' },
        { text: '動作', value: 'actions', sortable: false }
      ],
      products: []
    }
  },
  mounted () {
    this.readProduct()
  },
  methods: {
    close () {
      this.product = {
        item: null,
        postCount: null,
        price: null,
        during: null,
        isVisible: false,
        priceType: ''
      }
      this.select = { state: '首購', abbr: 'first_purchase' }
      this.dialog = false
    },

    readProduct () {
      payAPI.readProduct().then((response) => {
        this.products = response.data
      })
    },
    createProduct () {
      this.product.priceType = this.select.abbr
      payAPI.createProduct(this.product).then((response) => {
        if (response.data === true) {
          alert('新增成功')
          this.readProduct()
          this.close()
        } else {
          alert('新增失敗，請檢查欄位')
        }
      })
    },
    reviseProductVisible (item) {
      let message = ''
      let isVisible = false
      if (item.isVisible === true) {
        message = '修改為不可見'
        isVisible = false
      } else {
        message = '修改為可見'
        isVisible = true
      }
      if (confirm(message) === true) {
        const product = { id: 1, isVisible: false }
        product.id = item.id
        product.isVisible = isVisible
        payAPI.reviseProductVisible(product).then((response) => {
          this.readProduct()
        })
      }
    }
  }
}
</script>
