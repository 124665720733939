<template lang="pug">
v-dialog(v-model='dialog' width='400')
  template(v-slot:activator='{ on, attrs }')
    .btn.bg-white(v-bind='attrs', v-on='on')
      v-tooltip(top)
        template(v-slot:activator="{ on, attrs }")
          img.w-3.h-3(src="@/assets/profile/edit_profile.png" v-bind='attrs' v-on='on')
        span 編輯
  v-card.p-8
    .text-2xl.font-medium 編輯目錄
      .h-8
      .text-base.font-medium 名稱
      .input-pri.input-outline.bg-gray-100.p-2.rounded.mr-4.w-full.text-base
        input.w-full(v-model='directory.path' placeholder="名稱")
      .h-8( v-if="!currentPath.startsWith('home/track')")
      .flex.justify-between(@click="directory.share = !directory.share" v-if="!currentPath.startsWith('home/track')")
        .text-base.font-medium 是否公開收藏品
        .flex
          input(v-model='directory.share' type='checkbox' class='ios8-switch')
          label()
      .cursor-pointer.text-pri.text-base.underline.my-8(@click='deleteDirectory') 刪除目錄
      .flex.justify-between
        .btn.bg-gray.mr-2.text-base(color='primary' text='' @click='close') 取消
        .btn.bg-pri.text-base(color='primary' text='' @click='reviseDirectory') 保存
</template>
<script>
import directoryAPI from '../../../apis/directory'

export default {
  name: 'ReviseDirectory',
  props: ['directoryID', 'currentPath', 'directoryName'],
  data () {
    return {
      dialog: false,
      directory: {
        path: '',
        share: false
      },
      directoryInputDS: {
        path: '',
        directoryName: ''
      }
    }
  },
  mounted () {
    this.$set(this.directoryInputDS, 'path', this.currentPath)
    this.$set(this.directoryInputDS, 'directoryName', this.directoryName)
  },
  watch: {
    dialog: function (newDialog, oldDialog) {
      if (newDialog) { this.findDirectoryByID(this.directoryID) }
    }
  },
  methods: {
    close () {
      this.dialog = false
    },
    findDirectoryByID (directoryID) {
      directoryAPI.findDirectoryByID(directoryID).then((response) => {
        this.directory = response.data
        this.directory.path = this.directoryName
      })
    },
    reviseDirectory () {
      this.directory.path = this.currentPath + '/' + this.directory.path
      directoryAPI.reviseDirectory(this.directory).then(() => {
        this.$emit('readDirectory')
        this.close()
      })
    },
    deleteDirectory () {
      if (this.currentPath.startsWith('home/track')) {
        if (confirm('確認刪除?') === true) {
          directoryAPI.deleteDirectory(this.directoryInputDS).then((response) => {
            if (response) {
              this.dialog = false
              this.$emit('readDirectory')
            }
          })
        }
      } else if (this.currentPath.startsWith('home/upload')) {
        if (confirm('將永遠移除此資料夾與資料夾內的藏品，此動作無法復原，確認刪除?\n\n※注意：此動作會花費較久的時間，請等待成功提示出現※') === true) {
          directoryAPI.deleteUploadDirectory(this.directoryInputDS).then((response) => {
            if (response) {
              alert('刪除成功')
              this.dialog = false
              this.$emit('readDirectory')
            }
          })
        }
      }
    }
  }
}
</script>
<style scoped>
.input-outline{
    background-color: transparent;
    border: 1px rgba(128, 128, 128, 0.336) solid;
    caret-color: #C2321F;
}
input[type="checkbox"].ios8-switch {
    position: absolute;
    margin: 8px 0 0 16px;
}
input[type="checkbox"].ios8-switch + label {
    position: relative;
    padding: 5px 0 0 50px;
    line-height: 2.0em;
}
input[type="checkbox"].ios8-switch + label:before {
    content: "";
    position: absolute;
    display: block;
    left: 0;
    top: 0;
    width: 40px; /* x*5 */
    height: 24px; /* x*3 */
    border-radius: 16px; /* x*2 */
    background: #fff;
    border: 1px solid #d9d9d9;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
}
input[type="checkbox"].ios8-switch + label:after {
    content: "";
    position: absolute;
    display: block;
    left: 2px;
    top: 2px;
    width: 20px; /* x*3 */
    height: 20px; /* x*3 */
    border-radius: 16px; /* x*2 */
    background: #fff;
    border: 1px solid #d9d9d9;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
}
input[type="checkbox"].ios8-switch + label:hover:after {
    box-shadow: 0 0 5px rgba(0,0,0,0.2);
}
input[type="checkbox"].ios8-switch:checked + label:after {
    margin-left: 16px;
}
input[type="checkbox"].ios8-switch:checked + label:before {
    background: #C2321F;
}

/* SMALL */

input[type="checkbox"].ios8-switch-sm {
    margin: 5px 0 0 10px;
}
input[type="checkbox"].ios8-switch-sm + label {
    position: relative;
    padding: 0 0 0 32px;
    line-height: 1.3em;
}
input[type="checkbox"].ios8-switch-sm + label:before {
    width: 25px; /* x*5 */
    height: 15px; /* x*3 */
    border-radius: 10px; /* x*2 */
}
input[type="checkbox"].ios8-switch-sm + label:after {
    width: 15px; /* x*3 */
    height: 15px; /* x*3 */
    border-radius: 10px; /* x*2 */
}
input[type="checkbox"].ios8-switch-sm + label:hover:after {
    box-shadow: 0 0 3px rgba(0,0,0,0.3);
}
input[type="checkbox"].ios8-switch-sm:checked + label:after {
    margin-left: 10px; /* x*2 */
}

/* LARGE */

input[type="checkbox"].ios8-switch-lg {
    margin: 10px 0 0 20px;
}
input[type="checkbox"].ios8-switch-lg + label {
    position: relative;
    padding: 7px 0 0 60px;
    line-height: 2.3em;
}
input[type="checkbox"].ios8-switch-lg + label:before {
    width: 50px; /* x*5 */
    height: 30px; /* x*3 */
    border-radius: 20px; /* x*2 */
}
input[type="checkbox"].ios8-switch-lg + label:after {
    width: 30px; /* x*3 */
    height: 30px; /* x*3 */
    border-radius: 20px; /* x*2 */
}
input[type="checkbox"].ios8-switch-lg + label:hover:after {
    box-shadow: 0 0 8px rgba(0,0,0,0.3);
}
input[type="checkbox"].ios8-switch-lg:checked + label:after {
    margin-left: 20px; /* x*2 */
}
</style>
