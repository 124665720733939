<template lang="pug">
.login.flex.w-screen.h-full.paper-bg
  .login-bg.h-full.hidden(class="md:block")
  .login-page.h-full.flex.justify-center.items-start.flex-col
    .flex.p-2.flex-col.justify-center(class="md:px-56")
      .flex
        .font-bold.text-3xl 歡迎回來！
        .flex-1
        img.w-10.h-10(src="@/assets/logo_no_txt.png")
      .text-red-500.cursor-pointer.mb-10(@click="$emit('nextPage', 2)") 還沒加入嗎？立即註冊
      .text-base 使用社交帳戶登入
      .flex.mt-4.max-h-min(style="flex: 0 !important")
        a.w-10.h-10.mr-2(:href="fbLoginURL")
          img.w-10.h-10(src="@/assets/login/Facebook.png")
        a.w-10.h-10(:href="googleLoginURL")
          img.w-10.h-10(src="@/assets/login/Google.png")
      .i
        img.login-or.my-7.w-full(src="@/assets/login/or.png")
      .text-base.font-medium.mb-5 使用電子郵件登入
      input.login-input.rounded-sm.bg-white.px-3.py-2.mb-3(placeholder="請輸入電子郵件" v-model="user.username")
      input.login-input.rounded-sm.bg-white.px-3.py-2(placeholder="請輸入密碼" v-model="user.password" type="password" ref="password" @keydown.stop.prevent.enter="getToken")
      .text-base.self-end.text-sm.underline.text-blue-600.mt-1.cursor-pointer(@click="$emit('nextPage', 3)") 忘記密碼?
      .bg-pri.login-btn.mt-10.bg-gray-600.text-white.px-4.py-3.rounded.max-w-max.cursor-pointer(@click="getToken") 登入
</template>
<script>
import serverIP from '@/apis/common'
import userAPI from '@/apis/user'

export default {
  name: 'Login',
  data () {
    return {
      passwordVisible: false,
      user: {
        username: '',
        password: ''
      },
      frontEndServer: '',
      fbLoginURL: '',
      googleLoginURL: ''
    }
  },
  mounted () {
    this.frontEndServer = serverIP.frontEndServer
    this.fbLoginURL = `https://www.facebook.com/v15.0/dialog/oauth?client_id=4123752121082557&redirect_uri=${this.frontEndServer}/third_party_login/fb&state={state-param}&response_type=code&scope=public_profile email`
    this.googleLoginURL = `https://accounts.google.com/o/oauth2/v2/auth?client_id=137072900737-h8ud0r0ubjed2tb0vv1j3s7fk5gn1mo4.apps.googleusercontent.com&redirect_uri=${this.frontEndServer}/third_party_login/google&response_type=code&scope=profile email`
  },
  methods: {
    async init () {
      await this.$root.$data.init()
    },
    async getToken () {
      await userAPI.login(this.user).then((response) => {
        localStorage.setItem('user-token', response.data.access_token)
        const nowtime = new Date()
        localStorage.setItem('user-token-timeout', nowtime.setTime(nowtime.getTime() + (response.data.expires_in * 1000)))
      }).catch((error) => {
        if (error.response.data.error === 'invalid_grant') {
          alert('電子郵件或密碼錯誤')
        }
      })
      if (localStorage.getItem('user-token') != null) {
        await this.init()
        this.$router.push('/home')
      }
    }
  }
}
</script>

<style>
.login{
  box-sizing: border-box;
}
.paper-bg{
  background-image: url('~@/assets/background/paper_bg.png');
  background-position: center;
  background-size: cover;
}
.login-bg{
  background-image: url('~@/assets/login/login_bg.png');
  background-position: center;
  background-size: cover;
  flex:4;
}
.login-page{
  flex:6 !important;
  box-sizing: border-box;
  /* max-width: min-content; */
}

.login-or{
  min-width: 338px;
}

.login-input{
  outline: #DEDEDE solid 1px;
  min-width: 338px;
}
.login-btn{
  background-color: #3C3D3D;
}
</style>
