import requestHelper from './helper'
import axios from 'axios'
import serverIP from './common'

const payURL = '/pay'

const payAPI = {
  isConsumed: () => (requestHelper.get(`${payURL}/isConsumed`)),
  createOrder: pricePlan => (requestHelper.post(`${payURL}/createOrder/${pricePlan}`)),
  searchUserOrder: readUserID => (requestHelper.get(`${payURL}/searchUserOrder/${readUserID}`)),
  cancelOrder: ordersID => (requestHelper.post(`${payURL}/cancelOrder/${ordersID}`)),
  createProduct: product => (requestHelper.post(`${payURL}/createProduct`, product)),
  readProduct: () => (axios.get(`${serverIP.backEndServer}${payURL}/readProduct`)),
  reviseProductVisible: product => (requestHelper.post(`${payURL}/reviseProductVisible`, product))
}

export default payAPI
